import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';
import { User } from '../../../../store/auth';

export type UserForm = Pick<User, 'fullName' | 'email' | 'status'>

export interface AdministratorContextProps {
  initialState: UserForm | null,
  setInitialState: (data: UserForm) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: UserForm) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const AdministratorContext = createContext<AdministratorContextProps>(defaultValue);

export interface AdministratorProviderProps {
  children: React.ReactNode
}

function AdministratorProvider({
  children,
}: AdministratorProviderProps) {
  const [initialState, setInitialState] = useState<UserForm | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <AdministratorContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </AdministratorContext.Provider>
  );
}

export default AdministratorProvider;

export const useAdministratorContext = ():
  AdministratorContextProps => React.useContext(AdministratorContext);
