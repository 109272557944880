export enum DHLOptionalServiceCodes {
  adultSignature = 'SD',
  neutralDelivery = 'NN',
  directSignature = 'SF',
  noSignatureRequired = 'SX',
  brokerNotification = 'WG',
  dangerousGoods = 'HE',
  limitedQuantity = 'HL',
  exceptedQuantity = 'HH',
  dryIce = 'HC',
  lithiumMetal969 = 'HM',
  lithiumIon966 = 'HD',
  lithiumIon967 = 'HV',
  lithiumMetal970 = 'HW',
}

export enum ServiceEnumFedex {
  FEDEX_PRIORITY_FREIGHT = 'FedEx Priority Freight (Domestic freight + 68 KG)',
  FEDEX_INTERNATIONAL_PRIORITY = 'FedEx International Priority (IP – 68 Kg)',
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS = 'FedEx International Priority Express (IPE – 68 Kg)',
  INTERNATIONAL_ECONOMY_FREIGHT = 'FedEx International Economy Freight (IEF + 68 Kg)',
  INTERNATIONAL_PRIORITY_FREIGHT = 'FedEx International Priority Freight (IPF + 68 Kg)',
  FEDEX_PRIORITY_EXPRESS_FREIGHT = 'FedEx Priority Express Freight (Domestic Freight + 68 Kg noon del)',
  INTERNATIONAL_ECONOMY = 'FedEx International Economy (IE – 68 Kg)',
  FEDEX_PRIORITY = 'FedEx Priority (Dom Priority Overnight -68 Kg)',
  FEDEX_PRIORITY_EXPRESS = 'FedEx Priority Express (Dom Priority Overnight -68 Kg Noon del)',
  FEDEX_REGIONAL_ECONOMY = 'FedEx Regional Economy (RE -68 kg truck)',
  FEDEX_REGIONAL_ECONOMY_FREIGHT = 'FedEx Regional Economy Freight (REF +68 kg truck)',
} // Use if will be needed to display option key on view page.

export default {
  ServiceEnumFedex,
};
