import React, {
  createContext, isValidElement, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { App, Form, FormInstance } from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MessageInstance, MessageType } from 'antd/es/message/interface';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { getOnlyValueRecursive } from '../../../../utils';
import {
  Goods,
  Order,
  OrderCreateParams,
  OrderProformaInvoicesData,
  OrdersTransportDocumentsResponse,
  OrdersTransportDocumentsResponseError,
  OrderUpdateParams,
  useOrderCreate,
  useOrderProformaInvoicesGet,
  useOrdersTransportDocumentsGet,
  useOrderUpdate,
} from '../../../../hooks/api/order';
import { useMessageError, useMessageModalError, useMessageSuccess } from '../../../../hooks/common';
import {
  DefaultFetchError, FetchCreate, FetchGet, FetchUpdate,
} from '../../../../hooks/fetch';
import { ClientData, ClientDataPackages, getServerData } from '../Adapter';
import { DeliveryService, ModeOfTransport } from '../Adapter/enums';

interface ErrorFormat {
  tab: string;
  list: string[];
}

function useValidForm(): (data: ClientData | AnyObject) => boolean {
  const refCloseMessage = useRef<MessageType | undefined>();
  const { message } = App.useApp();
  const onClose = useCallback((e: Event) => {
    if ((e.target as HTMLElement)?.closest('.anticon-close-circle')
      && (e.target as HTMLElement)?.closest('.ant-message-notice-error')
      && refCloseMessage.current
    ) {
      refCloseMessage.current();
      refCloseMessage.current = undefined;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onClose, false);

    return () => document.removeEventListener('click', onClose);
  }, []);

  return useCallback((data: ClientData | AnyObject): boolean => {
    const listError = [];

    if (!data.general.company) {
      const general: ErrorFormat = {
        tab: 'General',
        list: [
          'Company',
        ],
      };

      listError.push(general);
    }

    const shipper: ErrorFormat = {
      tab: 'Shipper',
      list: [],
    };

    if (!data.shipper.company) {
      shipper.list.push('Company');
    }
    if (!data.shipper.contactName) {
      shipper.list.push('Contact name');
    }
    if (!data.shipper.phone) {
      shipper.list.push('Mobile number');
    }
    if (!data.shipper.email) {
      shipper.list.push('Email');
    }
    if (!data.shipper.country) {
      shipper.list.push('Company');
    }
    if (!data.shipper.address) {
      shipper.list.push('Address');
    }
    if (!data.shipper.postalCode) {
      shipper.list.push('Postal code');
    }
    if (!data.shipper.city) {
      shipper.list.push('City / Town');
    }
    if (!data.shipper.state) {
      shipper.list.push('Province / Region / State');
    }
    if (data.shipper?.isAddressDifferent) {
      if (!data.shipper.details?.company) {
        shipper.list.push('Pickup Details > Company');
      }
      if (!data.shipper.details?.contactName) {
        shipper.list.push('Pickup Details > Contact name');
      }
      if (!data.shipper.details?.phone) {
        shipper.list.push('Pickup Details > Mobile number');
      }
      if (!data.shipper.details?.email) {
        shipper.list.push('Pickup Details > Email');
      }
      if (!data.shipper.details?.country) {
        shipper.list.push('Pickup Details > company');
      }
      if (!data.shipper.details?.address) {
        shipper.list.push('Pickup Details > Address');
      }
      if (!data.shipper.details?.postalCode) {
        shipper.list.push('Pickup Details > Postal code');
      }
      if (!data.shipper.details?.city) {
        shipper.list.push('Pickup Details > City / Town');
      }
      if (!data.shipper.details?.state) {
        shipper.list.push('Pickup Details > Province / Region / State');
      }
    }

    if (shipper.list.length > 0) {
      listError.push(shipper);
    }

    const importer: ErrorFormat = {
      tab: 'Consignee',
      list: [],
    };

    if (!data.importer.company) {
      importer.list.push('Company');
    }
    if (!data.importer.contactName) {
      importer.list.push('Contact name');
    }
    if (!data.importer.phone) {
      importer.list.push('Mobile number');
    }
    if (!data.importer.email) {
      importer.list.push('Email');
    }
    if (!data.importer.country) {
      importer.list.push('Company');
    }
    if (!data.importer.address) {
      importer.list.push('Address');
    }
    if (!data.importer.postalCode) {
      importer.list.push('Postal code');
    }
    if (!data.importer.city) {
      importer.list.push('City / Town');
    }
    if (!data.importer.state) {
      importer.list.push('Province / Region / State');
    }
    if (data.importer.isAddressDifferent) {
      if (!data.importer.details?.company) {
        importer.list.push('Delivery Details > Company');
      }
      if (!data.importer.details?.contactName) {
        importer.list.push('Delivery Details > Contact name');
      }
      if (!data.importer.details?.phone) {
        importer.list.push('Delivery Details > Mobile number');
      }
      if (!data.importer.details?.email) {
        importer.list.push('Delivery Details > Email');
      }
      if (!data.importer.details?.country) {
        importer.list.push('Delivery Details > company');
      }
      if (!data.importer.details?.address) {
        importer.list.push('Delivery Details > Address');
      }
      if (!data.importer.details?.postalCode) {
        importer.list.push('Delivery Details > Postal code');
      }
      if (!data.importer.details?.city) {
        importer.list.push('Delivery Details > City / Town');
      }
      if (!data.importer.details?.state) {
        importer.list.push('Delivery Details > Province / Region / State');
      }
    }

    if (importer.list.length > 0) {
      listError.push(importer);
    }

    const delivery: ErrorFormat = {
      tab: 'Delivery',
      list: [],
    };

    if (data.delivery?.deliveryService) {
      const {
        shipmentCreation,
        deliveryService,
        serviceName,
        trackingNumber,
        trackingLink,
        serviceType,
        accountNumber,
        modeOfTransport,
        portDischarge,
        portLoading,
        departureAirport,
        destinationAirport,
      } = data.delivery;

      // eslint-disable-next-line default-case
      switch (deliveryService) {
        case DeliveryService.OTHER:
          if (!serviceName) {
            delivery.list.push('serviceName');
          }
          if (!trackingNumber) {
            delivery.list.push('trackingNumber');
          }
          if (!trackingLink) {
            delivery.list.push('trackingLink');
          }
          break;

        case DeliveryService.FEDEX:
          if (!shipmentCreation) {
            delivery.list.push('shipmentCreation');
          }
          if (shipmentCreation === 'Manually') {
            if (!trackingNumber) {
              delivery.list.push('trackingNumber');
            }
          } else if (!serviceType) {
            delivery.list.push('serviceType');
          }
          break;

        case DeliveryService.DHL:
          if (!shipmentCreation) {
            delivery.list.push('shipmentCreation');
          }
          if (!accountNumber) {
            delivery.list.push('accountNumber');
          }
          if (shipmentCreation === 'Manually') {
            if (!trackingNumber) {
              delivery.list.push('trackingNumber');
            }
          } else if (!serviceType) {
            delivery.list.push('serviceType');
          }
          break;

        case DeliveryService.SCHENKER:
          if (!shipmentCreation) {
            delivery.list.push('shipmentCreation');
          }
          if (!serviceType) {
            delivery.list.push('serviceType');
          }
          if (shipmentCreation === 'Manually' && !trackingNumber) {
            delivery.list.push('trackingNumber');
          }
          break;
      }

      // eslint-disable-next-line default-case
      switch (modeOfTransport) {
        case ModeOfTransport.IMDG:
          if (!portDischarge) {
            delivery.list.push('portDischarge');
          }
          if (!portLoading) {
            delivery.list.push('portLoading');
          }
          break;

        case ModeOfTransport.IATA:
          if (!departureAirport) {
            delivery.list.push('departureAirport');
          }
          if (!destinationAirport) {
            delivery.list.push('destinationAirport');
          }
          break;
      }
    }

    if (delivery.list.length > 0) {
      listError.push(delivery);
    }

    if (data.goods?.goods?.length > 0) {
      const goods: ErrorFormat = {
        tab: 'Products',
        list: [],
      };

      data.goods.goods.forEach(({ name, permit }: Goods, i: number) => {
        if (!name) {
          goods.list.push(`${(i + 1)} > Name`);
        }
        if (!permit) {
          goods.list.push(`${(i + 1)} > Permit`);
        }
      });

      if (goods.list.length > 0) {
        listError.push(goods);
      }
    }

    if (data.packages?.packages?.length > 0) {
      const packages: ErrorFormat = { tab: 'Packages', list: [] };

      if ((data.packages?.packageSummary?.length || 0) > 70) {
        packages.list.push('Shipment summary can\'t be longer than 70 characters');
      }

      // data.packages.packages.forEach(({ kind }: ClientDataPackages, i: number) => {
      //   if (!kind) {
      //     packages.list.push(`${(i + 1)} > Package`);
      //   }
      // });

      if (packages.list.length > 0) {
        listError.push(packages);
      }
    }

    if (listError.length > 0) {
      message.destroy();
      refCloseMessage.current = message.error(
        (
          <>
            <span style={{ fontWeight: 'bold' }}>
              Please fill in all required fields
            </span>
            <div
              style={{
                textAlign: 'left',
                maxHeight: '90vh',
                overflow: 'auto',
                margin: '8px 0',
              }}
            >
              {listError.map(({ tab, list }, i) => (
                <div key={tab} style={{ marginTop: 8, marginBottom: i === (listError.length - 1) ? 0 : 12 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {tab}
                    :
                  </span>
                  {list.map((err) => (
                    <div key={err} style={{ marginLeft: 16 }}>{err}</div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ),
        5,
        () => {
          refCloseMessage.current = undefined;
        },
      );

      return false;
    }

    return true;
  }, []);
}

export const formNames = ['general', 'shipper', 'importer', 'goods', 'packages', 'documents', 'delivery'];
export type FormNames = typeof formNames[number];

export type Validator = (form: FormInstance) => boolean;

export interface FormName {
  formName: FormNames;
}

export interface OrderContextFormProps {
  initialStates: { [key: FormNames]: AnyObject },
  setInitialState: (key: FormNames, validator: AnyObject) => void,
  isValid: boolean;
  generalForm: FormInstance;
  shipperForm: FormInstance;
  consigneeForm: FormInstance;
  goodsForm: FormInstance;
  packagesForm: FormInstance;
  documentsForm: FormInstance;
  deliveryForm: FormInstance;
  setValidator: (key: FormNames, validator: Validator) => void;
  forms: { [key: FormNames]: FormInstance };
  triggerValidationAllForm: () => void;
  loadingFile: boolean,
  setLoadingFile: (key: string, value: boolean) => void,
  orderProformaInvoicesGet: FetchGet<
    OrderProformaInvoicesData,
    undefined,
    DefaultFetchError,
    OrderProformaInvoicesData
  >
  ordersTransportDocumentsGet: FetchGet<
    OrdersTransportDocumentsResponse,
    undefined,
    OrdersTransportDocumentsResponseError,
    OrdersTransportDocumentsResponse
  >
  setStopBlocker: (value: boolean) => void;
  orderCreate: FetchCreate<Order, DefaultFetchError, OrderCreateParams>;
  orderUpdate: FetchUpdate<Order, DefaultFetchError, OrderUpdateParams>;
  orderSave: (e?: React.MouseEvent<HTMLElement>, callback?: () => void) => void;
  generateProcess: null |(() => void);
  ordersTransportDocumentsGenerate: () => void;
  orderProformaInvoicesGenerate: () => void;
}

const defaultValue = {
  initialStates: {},
  setInitialState: (key: FormNames, validator: AnyObject) => {
    // default
  },
  isValid: false,
  generalForm: {} as FormInstance,
  shipperForm: {} as FormInstance,
  consigneeForm: {} as FormInstance,
  goodsForm: {} as FormInstance,
  packagesForm: {} as FormInstance,
  documentsForm: {} as FormInstance,
  deliveryForm: {} as FormInstance,
  setValidator: (key: FormNames, validator: Validator) => {
    // default
  },
  forms: {},
  triggerValidationAllForm: () => {
    // default
  },
  loadingFile: false,
  setLoadingFile: (key: string, value: boolean) => {
    // default
  },
  orderProformaInvoicesGet: {} as FetchGet<
    OrderProformaInvoicesData,
    undefined,
    DefaultFetchError,
    OrderProformaInvoicesData
  >,
  ordersTransportDocumentsGet: {} as FetchGet<
    OrdersTransportDocumentsResponse,
    undefined,
    OrdersTransportDocumentsResponseError,
    OrdersTransportDocumentsResponse
  >,
  orderCreate: {} as FetchCreate<Order, DefaultFetchError, OrderCreateParams>,
  orderUpdate: {} as FetchUpdate<Order, DefaultFetchError, OrderUpdateParams>,
  orderSave: (e?: React.MouseEvent<HTMLElement>, callback?: () => void) => {
    // default
  },
  generateProcess: null,
  ordersTransportDocumentsGenerate: () => {
    // default
  },
  orderProformaInvoicesGenerate: () => {
    // default
  },
  setStopBlocker: (value: boolean) => {
    // default
  },
};

export const OrderContextForm = createContext<OrderContextFormProps>(defaultValue);

export interface OrderProviderProps {
  children: React.ReactNode;
}

function OrderProviderForm({
  children,
}: OrderProviderProps) {
  const isValidForm = useValidForm();
  const { message } = App.useApp();
  const { id } = useParams();
  const [loadingFiles, setLoadingFiles] = useState<{ [key: string]: boolean }>({});
  const loadingFile = useMemo(() => Object.values(loadingFiles).some((value) => value), [loadingFiles]);
  const [searchParams] = useSearchParams();

  const [generalForm] = Form.useForm();
  const [shipperForm] = Form.useForm();
  const [consigneeForm] = Form.useForm();
  const [goodsForm] = Form.useForm();
  const [packagesForm] = Form.useForm();
  const [documentsForm] = Form.useForm();
  const [deliveryForm] = Form.useForm();
  const forms = useMemo<{ [key: FormNames]: FormInstance }>(() => ({
    general: generalForm,
    shipper: shipperForm,
    importer: consigneeForm,
    goods: goodsForm,
    packages: packagesForm,
    documents: documentsForm,
    delivery: deliveryForm,
  }), []);
  const navigate = useNavigate();
  const orderCreate = useOrderCreate();
  const orderUpdate = useOrderUpdate(id);

  useMessageError([orderCreate, orderUpdate]);
  useMessageSuccess([orderCreate, orderUpdate], 'Changes saved successfully');

  useEffect(() => {
    if (!orderCreate.error && !orderCreate.loading && orderCreate.data) {
      setTimeout(() => setStopBlocker(false), 100);
      const currentTab = searchParams.get('tab');

      navigate(`/orders/${orderCreate.data.id}/edit${currentTab ? `?tab=${currentTab}` : ''}`);
    }
  }, [orderCreate.error, orderCreate.loading, orderCreate.data]);

  const orderProformaInvoicesGet = useOrderProformaInvoicesGet(id);
  const ordersTransportDocumentsGet = useOrdersTransportDocumentsGet(id);
  const ordersTransportDocumentsGetRef = useRef(null);

  const [stopBlocker, setStopBlocker] = useState(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [initialStates, setInitialStates] = useState<{ [key: FormNames]: AnyObject }>({});
  const [validators, setValidators] = useState<{ [key: FormNames]: Validator}>({});

  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (orderProformaInvoicesGet.data?.location ? false : (formNames.some((nameForm) => {
      if (stopBlocker) { // TODO replace with blocker-disabler context value
        return false;
      }

      if (!initialStates[nameForm]) {
        try {
          return getOnlyValueRecursive(forms[nameForm].getFieldsValue()) !== '';
        } catch {
          return true;
        }
      }

      const { id: _, createdAt: __, ...state } = initialStates[nameForm] || {};

      try {
        return getOnlyValueRecursive(state) !== getOnlyValueRecursive(forms[nameForm].getFieldsValue());
      } catch {
        return true;
      }
    }))),
    [stopBlocker, initialStates, forms, triggerChange, orderProformaInvoicesGet.data?.location],
  );

  useMessageError([orderProformaInvoicesGet]);
  const contextHolderModalError = useMessageModalError([
    [ordersTransportDocumentsGet, 'Transport Documents generation error'],
  ]);

  useEffect(() => {
    if (ordersTransportDocumentsGet.data
      && !ordersTransportDocumentsGet.error
      && !ordersTransportDocumentsGet.loading
    ) {
      if (ordersTransportDocumentsGetRef.current !== ordersTransportDocumentsGet.data) {
        message.success('Transport Documents generated successfully');
      }
      // eslint-disable-next-line no-unused-expressions
      ordersTransportDocumentsGetRef.current === ordersTransportDocumentsGet.data;
    }
  }, [ordersTransportDocumentsGet.data]);

  useEffect(() => {
    if (orderProformaInvoicesGet.data?.location) {
      try {
        // Automatically downloads files when data appears
        // window.location.assign(orderProformaInvoicesGet.data?.location);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [orderProformaInvoicesGet.data]);

  const triggerValidationAllForm = useCallback(() => {
    setTriggerChange(Date.now());

    setValid(!loadingFile && !formNames.some((key) => {
      if (validators[key]) {
        // console.log(key, validators[key](forms[key]));

        return !validators[key](forms[key]);
      }

      return false;
    }));
  }, [loadingFile, validators, forms, setTriggerChange]);

  const setValidator = useCallback((key: FormNames, validator: Validator) => {
    setValidators((beforeState) => ({ ...beforeState, [key]: validator }));
  }, []);

  const setInitialState = useCallback((key: FormNames, initialState: AnyObject) => {
    setInitialStates((beforeState) => ({ ...beforeState, [key]: initialState }));
  }, []);

  const setLoadingFile = useCallback((key: string, value: boolean) => {
    if (value) {
      setLoadingFiles((beforeState) => ({ ...beforeState, [key]: value }));
    } else {
      setLoadingFiles((beforeState) => {
        if (typeof beforeState[key] !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          delete beforeState[key];
        }

        return { ...beforeState };
      });
    }
  }, []);

  const [generateProcess, setGenerateProcess] = useState<null |(() => void)>(null);

  const ordersTransportDocumentsGenerate = () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    setGenerateProcess(() => () => {
      setGenerateProcess(null);
      ordersTransportDocumentsGet.fetch();
    });
    orderSave();
  };
  const orderProformaInvoicesGenerate = () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    setGenerateProcess(() => () => {
      setGenerateProcess(null);
      orderProformaInvoicesGet.fetch();
    });
    orderSave();
  };

  useEffect(
    () => {
      if (generateProcess && typeof generateProcess === 'function') {
        try {
          if (orderUpdate.data && !orderUpdate.error && !orderUpdate.loading) {
            generateProcess();
          }

          if (orderCreate.data && !orderCreate.error && !orderCreate.loading) {
            setTimeout(generateProcess, 500);
          }
        } catch (error) {
          console.warn('Generate process:', error);
        }
      }
    },
    [orderUpdate.data, orderCreate.data],
  );

  const orderSave = (e?: React.MouseEvent<HTMLElement>, callback?: () => void) => {
    if (typeof callback === 'function') {
      // eslint-disable-next-line unicorn/consistent-function-scoping
      setGenerateProcess(() => () => {
        setGenerateProcess(null);
        callback();
      });
    }

    e?.preventDefault();

    const list: ClientData | AnyObject = {};

    formNames.forEach((key) => {
      list[key] = forms[key].getFieldsValue();
    });

    if (isValidForm(list)) {
      const data = getServerData(list as ClientData);

      setStopBlocker(true);

      (id ? orderUpdate : orderCreate).fetch(data).finally(() => {
        setInitialState('general', list.general);
        setInitialState('shipper', list.shipper);
        setInitialState('importer', list.importer);
        setInitialState('goods', list.goods);
        setInitialState('packages', list.packages);
        setInitialState('documents', list.documents);
        setInitialState('delivery', list.delivery);
      });
    } else {
      setGenerateProcess(null);
    }
  };

  return (
    <OrderContextForm.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setStopBlocker,
        loadingFile,
        setLoadingFile,
        initialStates,
        setInitialState,
        isValid,
        generalForm,
        shipperForm,
        consigneeForm,
        goodsForm,
        packagesForm,
        documentsForm,
        deliveryForm,
        forms,
        setValidator,
        triggerValidationAllForm,
        orderProformaInvoicesGet,
        ordersTransportDocumentsGet,
        orderCreate,
        orderUpdate,
        orderSave,
        generateProcess,
        ordersTransportDocumentsGenerate,
        orderProformaInvoicesGenerate,
      }}
    >
      {children}
      <div>
        {routeBlocker.contextHolder}
      </div>
      <div>
        {contextHolderModalError}
      </div>
    </OrderContextForm.Provider>
  );
}

export default OrderProviderForm;

export const useOrderContextForm = ():
  OrderContextFormProps => React.useContext(OrderContextForm);
