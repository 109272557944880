import React, { useEffect, useState } from 'react';

import {
  Form, FormInstance, Input, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  createRulesForAntd, createValidatorTextField, phone, email,
} from '../../../../utils/validations';
import { useCompanyContext } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { Company, useCompanyGetById } from '../../../../hooks/api/company';
import { useMessageError } from '../../../../hooks/common';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { getOnlyValueRecursive } from '../../../../utils';
import SelectPlace from '../../Orders/Form/Common/SelectPlace';

import styles from '../../../Common/Form/index.module.scss';

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);

export const validationCreateCompany = yup.object().shape({
  companyName: textFieldRequired,
  // participantType: textFieldRequired,
  contactName: textFieldRequired,
  phone,
  email,
  country: textFieldRequired,
  address1: textFieldRequired,
  address2: textField,
  postalCode: textFieldRequired,
  city: textFieldRequired,
  state: textFieldRequired,
  tinNumber: textField,
  registerNumber: textField,
});

const validationRules = createRulesForAntd(validationCreateCompany);

type CompanyFormProps = AnyObject

function FullAddress({ form }: { form: FormInstance; }) {
  return (
    <>
      <SelectPlace
        addressName="address1"
        type={['country']}
        form={form}
        label="Country"
        name="country"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
        onSelect={() => {
          ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
            form.setFieldValue(key, undefined);
          });
        }}
      />
      <SelectPlace
        addressName="address1"
        type={['address']}
        form={form}
        label="Address"
        name="address1"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <Form.Item<Company>
        label="Address 2"
        name="address2"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>
      <SelectPlace
        addressName="address1"
        type={['postal_code']}
        form={form}
        label="Postal code"
        name="postalCode"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['locality']}
        form={form}
        label="City / Town"
        name="city"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['administrative_area_level_1']}
        form={form}
        label="Province / Region / State"
        name="state"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
    </>
  );
}

export default function CompanyForm(props: CompanyFormProps): React.ReactNode | null {
  const { id } = useParams();
  const [form] = Form.useForm<Company>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useCompanyContext();
  const companyGetById = useCompanyGetById(id);
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );
  const { loading, data, error } = companyGetById;

  useMessageError([companyGetById]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      setInitialState(data);
      form?.setFieldsValue(data);
    }
  }, [loading, data, error]);

  return (
    <Form
      className={clsx(styles.form, styles.miniTopPadding)}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={() => {
        setTriggerChange(Date.now());

        setValid(!(Object.keys(form.getFieldsValue())
          .filter((key) => !(['address2', 'registerNumber', 'tinNumber'].includes(key)))
          .map((key) => form.getFieldValue(key))
          .some((value) => value === '' || value === undefined)
          || form.getFieldsError().some(({ errors }) => errors.length)));
      }}
      {...props}
    >
      <Form.Item<Company>
        label="Company"
        name="companyName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Contact name"
        name="contactName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Mobile number"
        name="phone"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Email"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <FullAddress form={form} />

      <Form.Item<Company>
        label="TIN number"
        name="tinNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Register number"
        name="registerNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      {loading ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : null}

      {routeBlocker.contextHolder}
    </Form>
  );
}
