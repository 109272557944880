import React, { useEffect, useRef } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../Modal/Simple';
import { useBlockerContext } from '../../../context/blockerDisabler';

const preventReload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  Object.assign(event, {
    returnValue: true,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useRouteBlocker(checkState?: () => boolean, dependencies: any[] = []) {
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const state = useRef(false);
  const { isBlockerDisabled } = useBlockerContext();

  useBlocker(({
    currentLocation,
    nextLocation,
  }) => {
    const value = state.current && currentLocation.pathname !== nextLocation.pathname;

    if (value) {
      open({
        icon: <ExclamationCircleFilled />,
        title: 'Unsaved changes',
        content: (
          <span>
            Are you sure you want to leave this page?
            <br />
            Changes you made will not be saved.
          </span>
        ),
        cancelText: 'Cancel',
        okText: 'Leave page',
        onOk: () => {
          state.current = false;
          window.removeEventListener('beforeunload', preventReload);
          navigate(nextLocation.pathname);
        },
      });
    }

    return value;
  });

  useEffect(() => {
    if (checkState) {
      /** If blocker disabled in context, then dont show confirm popup */
      const checkResult = isBlockerDisabled === false ? false : checkState();

      state.current = checkResult;
    }

    if (state.current) {
      window.addEventListener('beforeunload', preventReload, false);

      return () => window.removeEventListener('beforeunload', preventReload);
    }

    return () => {
      // do nothing
    };
  }, [...dependencies, checkState, isBlockerDisabled]);

  return { state, open, contextHolder };
}
