import React, { useEffect, useState } from 'react';
import { Layout as AntdLayout, Button } from 'antd';
import {
  LeftOutlined, RightOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import ReduxRedirect from '../Common/ReduxRedirect';

import styles from './index.module.scss';

const { Header, Sider, Content } = AntdLayout;

interface LayoutProps {
  title: string;
  LeftMenu?: React.FC<{ collapsed?: boolean }>;
  leftMenu?: React.ReactNode;
  transparent?: boolean;
  disableLeftMenu?: boolean;
  children: React.ReactNode;
  colapsedByDefault?: boolean;
  minWidth?: boolean;
  headerContent?: React.ReactNode;
}

let defaultStateCollapsed = false;

function Layout({
  disableLeftMenu = false,
  colapsedByDefault = false,
  title,
  LeftMenu,
  leftMenu,
  transparent,
  children,
  minWidth,
  headerContent,
}: LayoutProps): React.ReactNode {
  const [collapsed, setCollapsed] = useState(colapsedByDefault || defaultStateCollapsed);
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  const disableMenu = disableLeftMenu || (!LeftMenu && !leftMenu);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if (isTablet) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet]);

  return (
    <>
      <ReduxRedirect />

      <AntdLayout hasSider className={styles.layout}>
        {disableMenu ? null : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={clsx(styles.slider, { [styles.absolute]: isTablet && !disableMenu })}
            width={208}
            collapsedWidth={64}
          >
            <NavLink to="/" className={clsx(styles.logo, { [styles.collapsed]: collapsed })}>
              {collapsed ? <img src="/logo-mini.png" className={styles.logoSvg} alt="Triare" /> : null}
              {/* <LogoMini className={styles.logoSvg} /> */}

              {!collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Triare" /> : null}
              {/* <Logo className={styles.logoSvg} /> */}
            </NavLink>

            <div className={clsx(styles.menu, { [styles.collapsed]: collapsed })}>
              {LeftMenu ? <LeftMenu collapsed /> : leftMenu}
            </div>

            <Button
              type="text"
              icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              className={clsx(styles.btn, { [styles.collapsed]: collapsed })}
            />
          </Sider>
        )}
        <AntdLayout className={clsx(styles.wrapper, { [styles.tablet]: isTablet && !disableMenu })}>
          <Header
            className={clsx(styles.header, { [styles.headerLogo]: disableMenu })}
          >
            {disableMenu ? (
              <NavLink to="/" className={clsx(styles.logo, { [styles.tablet]: isTablet })}>
                {collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Triare" /> : null}
                {!collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Triare" /> : null}
              </NavLink>
            ) : null}

            <h1>
              {title}
            </h1>

            {headerContent}
          </Header>
          <div className={clsx(styles.scroll, { [styles.collapsed]: collapsed })}>
            <Content
              className={clsx(
                styles.content,
                { [styles.transparent]: transparent },
                { [styles.minWidth]: isTablet && minWidth },
              )}
              style={{ minHeight: 'calc(100vh - 64px - 24px)' }}
              id="layout-content"
            >
              {children}
            </Content>
          </div>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}

export default Layout;
