import React, { useEffect, useState } from 'react';

import { Breadcrumb, Button } from 'antd';
import {
  NavLink, useNavigate, useParams,
} from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import CompanyForm from '../../Form';
import { useCompanyContext } from '../../Form/context';
import {
  useCompanyDelete,
  useCompanyUpdate,
} from '../../../../../hooks/api/company';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useBlockerContext } from '../../../../../context/blockerDisabler';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    form, isValid, setInitialState,
  } = useCompanyContext();
  const companyUpdate = useCompanyUpdate(id);
  const companyDelete = useCompanyDelete(id || 'undefined');

  const { handleIsBlockerDisabled } = useBlockerContext();

  useMessageError([companyUpdate, companyDelete]);
  useMessageSuccess([companyUpdate], 'Changes saved successfully');
  useMessageSuccess([companyDelete], 'Company deleted successfully');

  useEffect(() => {
    if (!companyUpdate.error && !companyUpdate.loading && companyUpdate.data) {
      navigate(`/companies/${id}`);
    }
  }, [companyUpdate.error, companyUpdate.loading, companyUpdate.data]);

  useEffect(() => {
    if (!companyDelete.error && !companyDelete.loading && companyDelete.data) {
      /** After delete disable blocker, and after navigation - reset to default state */
      handleIsBlockerDisabled(false)
        .then(() => {
          navigate('/companies');
        }).then(() => handleIsBlockerDisabled(null));
    }
  }, [companyDelete.error, companyDelete.loading, companyDelete.data]);

  return (
    <Actions>
      <Button
        danger
        loading={companyDelete.loading}
        onClick={(e) => {
          e.preventDefault();

          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete company?',
            content: (
              <span>
                Are you sure you want to delete company
                {' '}
                <b>{form?.getFieldValue('companyName')}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => companyDelete.fetch(),
          });
        }}
      >
        Delete
      </Button>
      <Button
        type="primary"
        disabled={!isValid}
        loading={companyUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            companyUpdate.fetch(data);
          }
        }}
      >
        Save
      </Button>

      {contextHolder}
    </Actions>
  );
}

export default function CompanyEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { initialState } = useCompanyContext();

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/companies">Companies</NavLink>,
          },
          {
            title: <NavLink to={`/companies/${id}`}>{initialState?.companyName || 'Current Company'}</NavLink>,
          },
          {
            title: 'Edit company',
          },
        ]}
      />

      <CompanyForm />
    </>
  );
}
