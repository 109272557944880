import React, {
  createContext, useMemo, useState,
} from 'react';
import { asyncDelay } from '../utils';

export interface BlockerContextProps {
  isBlockerDisabled: boolean | null;
  handleIsBlockerDisabled: (val: boolean | null) => Promise<void>;
}

const defaultValue: BlockerContextProps = {
  isBlockerDisabled: null,
  handleIsBlockerDisabled: async () => undefined,
};

export const BlockerContext = createContext<BlockerContextProps>(defaultValue);

export interface BlockerProviderProps {
  children: React.ReactNode
}

function BlockerDisablerProvider({ children }: BlockerProviderProps) {
  const [isBlockerDisabled, setIsBlockerDisabled] = useState<boolean | null>(null);

  const handleIsBlockerDisabled = async (disabled: boolean | null) => {
    setIsBlockerDisabled(disabled);
    await asyncDelay(50);
  };

  const memoizedValue: BlockerContextProps = useMemo(() => ({
    isBlockerDisabled,
    handleIsBlockerDisabled,
  }), [isBlockerDisabled, setIsBlockerDisabled]);

  return (
    <BlockerContext.Provider
      value={memoizedValue}
    >
      {children}
    </BlockerContext.Provider>
  );
}

export default BlockerDisablerProvider;

export const useBlockerContext = ():
  BlockerContextProps => React.useContext(BlockerContext);
