import React, { useEffect, useState } from 'react';

import {
  Form, Input, Select, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import {
  createRulesForAntd, createValidatorEmail, createValidatorName,
} from '../../../../utils/validations';
import { useAdministratorContext, UserForm } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { useAdministratorGetById } from '../../../../hooks/api/administrator';
import { useMessageError } from '../../../../hooks/common';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { getOnlyValueRecursive } from '../../../../utils';

import styles from '../../../Common/Form/index.module.scss';

export const validationCreateAdministrator = yup.object().shape({
  fullName: createValidatorName([false, false, 'Required field']),
  email: createValidatorEmail([false, 'Required field', false]),
});

const validationRules = createRulesForAntd(validationCreateAdministrator);

type AdministratorFormProps = AnyObject

export default function AdministratorForm(props: AdministratorFormProps): React.ReactNode | null {
  const { id } = useParams();
  const [form] = Form.useForm<UserForm>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useAdministratorContext();
  const administratorGetById = useAdministratorGetById(id);
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );
  const { loading, data, error } = administratorGetById;

  useMessageError([administratorGetById]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      setInitialState({
        fullName: data.fullName,
        email: data.email,
        status: data.status,
      });
      form?.setFieldsValue({
        fullName: data.fullName,
        email: data.email,
        status: data.status,
      });
    }
  }, [loading, data, error]);

  return (
    <Form
      className={styles.form}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={() => {
        setTriggerChange(Date.now());
        setValid(!(Object.values(form.getFieldsValue()).some((value) => value === '' || value === undefined)
          || form.getFieldsError().some(({ errors }) => errors.length)));
      }}
      {...props}
    >
      <Form.Item<UserForm>
        label="Name"
        name="fullName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<UserForm>
        label="Email address"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input
          readOnly
          onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
        />
      </Form.Item>

      {id ? (
        <Form.Item<UserForm>
          label="Status"
          name="status"
        >
          <Select disabled />
        </Form.Item>
      ) : null}

      {loading ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : null}

      {routeBlocker.contextHolder}
    </Form>
  );
}
