import React, { useEffect, useMemo } from 'react';
import {
  Form, Input, InputNumber, Select, Tooltip,
} from 'antd';
import * as yup from 'yup';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import {
  createRulesForAntd, createValidatorTextField,
} from '../../../../../../../utils/validations';
import { ContentProps } from '../index';
import OrderInput from '../../../Common/Input';
import SelectInputDangerousGoods from '../../../Common/SelectInputDangerousGoods';
import SelectKindPackage from '../../../Common/SelectKindPackage';
import SelectMaterialCode from '../../../Common/SelectMaterialCode';
import SelectEmptyUncleaned from '../../../Common/SelectIsEmptyUncleaned';
import SelectEnvironmentallyHazardous from '../../../Common/SelectEnvironmentallyHazardous';
import SelectPhysicalState from '../../../Common/SelectPhysicalState';
import SelectPureMixSolution from '../../../Common/SelectPureMixSolution';
import OrderUpload from '../../../Common/Upload';
import OrderSwitch from '../../../Common/Switch';
import SelectSpecialTemperatureMode from '../../../Common/SelectSpecialTemperatureMode';
import SelectSegregationGroup from '../../../Common/SelectSegregationGroup';
import { FormName, useOrderContextForm } from '../../../context';
import SelectInnerPackagingType from '../../../Common/SelectInnerPackagingType';
import { ClientDataGood } from '../../../../Adapter';
import SelectHSCode from '../../../Common/HSCode';
import InputSelectGood from '../../../Common/InputSelectGood';
import { Mass, PhysicalState } from '../../../../Adapter/enums';
import SelectPermit from '../../../Common/SelectPermit';
import InputPermitNumberAndDate from '../../../Common/InputPermitNumberAndDate';
import { Goods } from '../../../../../../../hooks/api/order';
import { getFormattedUnNumbers, handleUnNumbersChange } from '../../../Delivery/utils';

import styles from '../../../index.module.scss';

interface ReCalcNetValueProps {
  net: number;
  innerPackagingNetUnitaryQuantity: string;
  innerPackagingQuantity: string;
}
export const reCalcNetValue = ({
  net, innerPackagingNetUnitaryQuantity, innerPackagingQuantity,
}: ReCalcNetValueProps) => {
  let value: string | number = net;

  if (innerPackagingNetUnitaryQuantity) {
    value = Number.parseFloat(innerPackagingNetUnitaryQuantity);
  }
  if (innerPackagingQuantity) {
    value = Number.parseFloat((((+value) || 0) * Number.parseFloat(innerPackagingQuantity)).toFixed(3));
  }

  return value;
};

interface ProductFormProps extends ContentProps, FormName {
  item: ClientDataGood;
}

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);

export default function ProductForm({ formName, item, index }: ProductFormProps) {
  const {
    setValidator, forms: { [formName]: form }, triggerValidationAllForm, generalForm,
  } = useOrderContextForm();

  useEffect(() => {
    setValidator(formName, () => !form.getFieldsValue().goods?.some(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (good: Goods) => ['name', 'permit'].some(((key) => good && good[key]?.toString().length === 0)),
    ) && !(form.getFieldsValue()[formName]
      .some((data: AnyObject) => (data && data.value ? data.value === '' || data.value === undefined : false))
        || form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const validationRules = useMemo(() => createRulesForAntd(yup.object().shape(
    {
      [formName]: yup.array().of(
        yup.object({
          name: textFieldRequired,
          permit: textFieldRequired,
        }),
      ),
    },
  ), form.getFieldsValue), [formName]);

  const dangerousGoods = Form.useWatch([formName, index, 'dangerousGoods'], form);
  const innerPackaging = Form.useWatch([formName, index, 'innerPackaging'], form);
  const temperatureMode = Form.useWatch([formName, index, 'temperatureMode'], form);
  const specialTemperatureMode = Form.useWatch([formName, index, 'specialTemperatureMode'], form);
  const isEmptyUncleaned = Form.useWatch([formName, index, 'isEmptyUncleaned'], form);
  const currency = Form.useWatch([formName, index, 'currency'], form);
  const massUnit = Form.useWatch([formName, index, 'massUnit'], form);
  const net = Form.useWatch([formName, index, 'net'], form);
  const gross = Form.useWatch([formName, index, 'gross'], form);
  const physicalState = Form.useWatch([formName, index, 'physicalState']);
  const isGrossWeightDisable = (typeof physicalState === 'object' && physicalState
    ? physicalState.value : physicalState) === PhysicalState.LIQUID && massUnit === Mass.LITER;
  const innerPackagingNetUnitaryQuantity = Form.useWatch([formName, index, 'innerPackagingNetUnitaryQuantity'], form);
  const innerPackagingQuantity = Form.useWatch([formName, index, 'innerPackagingQuantity'], form);
  const permit = Form.useWatch([formName, index, 'permit'], form);

  useEffect(() => {
    const calcValue = reCalcNetValue({
      net, innerPackagingNetUnitaryQuantity, innerPackagingQuantity,
    });

    form.setFieldValue([formName, index, 'net'], calcValue);
  }, [innerPackagingNetUnitaryQuantity, innerPackagingQuantity, innerPackaging]);

  const calcGross = () => {
    if (isGrossWeightDisable) {
      const densityValue = Number.parseFloat(form.getFieldValue([formName, index, 'density'])) || 1;
      const netValue = Number.parseFloat(form.getFieldValue([formName, index, 'net']));
      const value = netValue * densityValue;

      form.setFieldValue([formName, index, 'gross'], Number.isNaN(value) ? '' : value);
    }
  };

  const calcVolume = () => {
    form.setFieldValue(
      [formName, index, 'volume'],
      Number.parseFloat((
        ((Number.parseFloat(form.getFieldValue([formName, index, 'length'])) || 0) / 100)
        * ((Number.parseFloat(form.getFieldValue([formName, index, 'width'])) || 0) / 100)
        * ((Number.parseFloat(form.getFieldValue([formName, index, 'height'])) || 0) / 100)
      ).toFixed(3)),
    );
    triggerValidationAllForm();
  };

  /** Sync currency value between forms */
  const insuranceCurrencyWatch = Form.useWatch('insuranceCurrency', generalForm);

  useEffect(() => {
    if (insuranceCurrencyWatch) {
      form.setFieldValue([formName, index, 'currency'], insuranceCurrencyWatch);
    }
  }, [insuranceCurrencyWatch]);

  return (
    <div className={styles.productForm}>
      <h3>
        <span>General info</span>
      </h3>
      <OrderSwitch
        rest={item}
        label="Dangerous goods"
        prefix={formName}
        name={[index, 'dangerousGoods']}
      />
      <OrderSwitch
        rest={item}
        label="Inner packaging"
        prefix={formName}
        name={[index, 'innerPackaging']}
      />
      <InputSelectGood
        item={item}
        formName={formName}
        index={index}
        validationRules={validationRules}
        name={[index, 'name']}
      />
      {dangerousGoods ? (
        <SelectInputDangerousGoods
          formName={formName}
          rest={item}
          label="Dangerous goods"
          prefix={formName}
          name={[index, 'dangerousGood']}
          rightText="DGA, PI, Delivery"
        />
      ) : null}
      {innerPackaging ? (
        <OrderInput
          rest={item}
          inputProps={{
            disabled: true,
          }}
          label="Packaging group"
          prefix={formName}
          name={[index, 'packagingGroup']}
          rightText="DGA, PI, Delivery"
        />
      ) : null}
      <SelectHSCode
        rest={item}
        label="HS code"
        prefix={formName}
        name={[index, 'hsCode']}
        rightText="PI, Delivery"
      />
      <OrderInput
        rest={item}
        label="English Tech. Name"
        prefix={formName}
        name={[index, 'englishTechName']}
        rightText="DGA"
      />
      <OrderSwitch
        rest={item}
        label="Save Product To Database"
        prefix={formName}
        name={[index, 'saveProductToDatabase']}
      />
      <OrderInput
        rest={item}
        textarea
        label={(
          <span>
            Item Description
            {' '}
            <Tooltip
              placement="top"
              title={'Additional information for the "Item Description" field in the '
                + 'Proforma Invoice (Country of origin, Brand name, etc.).'}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        )}
        prefix={formName}
        name={[index, 'description']}
        rightText="PI"
      />
      <SelectKindPackage
        rest={item}
        label="Kind of Package"
        prefix={formName}
        name={[index, 'packaging']}
        rightText="DGA, PI, Delivery"
        selectProps={{
          onChange: (_, option) => {
            form.setFieldValue([formName, index, 'materialAndCode'], null);
            form.setFieldValue([formName, index, 'packaging'], option || undefined);
          },
        }}
      />

      <SelectMaterialCode
        rest={item}
        label="Material and Code"
        prefix={formName}
        name={[index, 'materialAndCode']}
        rightText="DGA"
      />
      <SelectEmptyUncleaned
        rest={item}
        label="Is Empty Uncleaned?"
        prefix={formName}
        name={[index, 'isEmptyUncleaned']}
        rightText="DGA"
      />

      <h3>
        <span>Parameters</span>
      </h3>
      <OrderInput
        rest={item}
        inputNumber
        label="Number of Packages"
        prefix={formName}
        name={[index, 'quantity']}
        rightText="DGA, PI, Delivery"
        inputNumberProps={{
          onChange: calcVolume,
          precision: 0,
        }}
      />
      <Form.Item name={[index, 'currency']} initialValue="EUR" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item
        {...item}
        label="Value"
        name={[index, 'value']}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          min={0}
          addonAfter={(
            <Select
              value={currency || 'EUR'}
              onChange={(value) => {
                form.setFieldValue([formName, index, 'currency'], value);
                generalForm.setFieldValue('insuranceCurrency', value); // Sync currency value between forms
                triggerValidationAllForm();
              }}
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
            )}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item name={[index, 'massUnit']} initialValue="kg" style={{ display: 'none' }}>
        <Input onChange={calcGross} />
      </Form.Item>

      {!(typeof isEmptyUncleaned === 'object' ? isEmptyUncleaned?.value : isEmptyUncleaned) ? (
        <Form.Item
          {...item}
          label="Net Quantity per Package"
          name={[index, 'net']}
          extra={(
            <div className={styles.text}>
              DGA
            </div>
          )}
          normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
        >
          <InputNumber
            disabled={innerPackaging}
            onChange={calcGross}
            min={0}
            addonAfter={(
              <Select
                value={massUnit || 'kg'}
                onChange={(value) => {
                  form.setFieldValue([formName, index, 'massUnit'], value);
                  calcGross();
                  triggerValidationAllForm();
                }}
              >
                <Select.Option value={Mass.KG}>
                  {Mass.KG}
                </Select.Option>
                <Select.Option value={Mass.LITER}>
                  {Mass.LITER}
                </Select.Option>
              </Select>
            )}
            style={{ width: '100%' }}
          />
        </Form.Item>
      ) : null}

      <OrderInput
        rest={item}
        label="Gross Weight per Package"
        prefix={formName}
        name={[index, 'gross']}
        rightText="Delivery"
        inputNumber
        // inputProps={{
        //   status: !isGrossWeightDisable && gross && Number.parseFloat(gross) && net > gross ? 'error' : undefined,
        //   suffix: 'kg',
        // }}
        inputNumberProps={{
          status: !isGrossWeightDisable && gross && Number.parseFloat(gross) && net > gross ? 'error' : undefined,
          suffix: 'kg',
          controls: false,
        }}
        normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
      />

      <Form.Item
        label="Length"
        className={styles.sizeLWH}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
      >
        <OrderInput
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          rest={item}
          name={[index, 'length']}
          rightText={false}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        />
        <OrderInput
          rest={item}
          label="Width"
          name={[index, 'width']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        />
        <OrderInput
          rest={item}
          label="Height"
          name={[index, 'height']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        />
      </Form.Item>

      <OrderInput
        rest={item}
        label="Volume per Package"
        prefix={formName}
        name={[index, 'volume']}
        rightText="Delivery"
        inputNumber
        inputNumberProps={{
          suffix: (
            <>
              m
              <sup>3</sup>
            </>
          ),
          controls: false,
        }}
      />

      {innerPackaging ? (
        <>
          <h3>
            <span>Inner packaging</span>
          </h3>
          <OrderInput
            inputNumber
            rest={item}
            label="Number of inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingQuantity']}
            rightText="DGA"
            inputNumberProps={{
              precision: 0,
            }}
          />
          <SelectInnerPackagingType
            rest={item}
            label="Inner packaging"
            prefix={formName}
            name={[index, 'innerPackagingType']}
            rightText="DGA"
          />
          <OrderInput
            rest={item}
            label="Net unitary quantity inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingNetUnitaryQuantity']}
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: massUnit || 'kg',
              controls: false,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
          <OrderInput
            rest={item}
            label="Net explosive mass per inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingNetExposiveQuantity']}
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: 'kg',
              controls: false,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
        </>
      ) : null}

      <h3>
        <span>Goods properties</span>
      </h3>
      <SelectEnvironmentallyHazardous
        rest={item}
        label="Environmentally Hazardous"
        prefix={formName}
        name={[index, 'environmentallyHazardous']}
        rightText="DGA"
      />
      <SelectPhysicalState
        rest={item}
        label="Physical State"
        prefix={formName}
        name={[index, 'physicalState']}
        rightText="DGA"
        selectProps={{
          onChange: calcGross,
        }}
      />
      <OrderInput
        rest={item}
        label="Density"
        prefix={formName}
        name={[index, 'density']}
        rightText="DGA"
        inputNumber
        inputNumberProps={{
          suffix: 'kg/L',
          controls: false,
          onChange: calcGross,
        }}
        normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
      />
      <SelectPureMixSolution
        form={form}
        className={styles.doubleInput}
        rest={item}
        label="Pure, Mix or Solution"
        prefix={formName}
        name={[[index, 'pureMixSolution'], [index, 'pureMixSolutionPercentage']]}
        rightText="DGA"
      />
      <OrderInput
        rest={item}
        label="Net explosive mass per package"
        prefix={formName}
        name={[index, 'netExplosivePerPackage']}
        rightText="DGA"
        inputNumber
        inputNumberProps={{
          suffix: 'kg',
          controls: false,
        }}
        normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
      />
      <OrderInput
        rest={item}
        label="Flash Point"
        prefix={formName}
        name={[index, 'flashPoint']}
        rightText="DGA"
        inputProps={{
          suffix: 'ºC c.c.',
        }}
      />
      {/* <SelectSegregation */}
      {/*  rest={item} */}
      {/*  label="Segregation" */}
      {/*  prefix={formName} */}
      {/*  name={[index, 'segregation']} */}
      {/*  rightText="DGA" */}
      {/* /> */}
      <SelectSegregationGroup
        rest={item}
        label="Segregation group"
        prefix={formName}
        name={[index, 'segregationGroup']}
        rightText="DGA"
      />

      <h3>
        <span>Export/Import permit</span>
      </h3>
      <SelectPermit
        rest={item}
        label="Permit"
        prefix={formName}
        name={[index, 'permit']}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="Delivery"
      />
      {permit === 'obligation' ? (
        <InputPermitNumberAndDate
          form={form}
          className={styles.doubleInput}
          rest={item}
          label="Permit Number & Date"
          prefix={formName}
          name={[[index, 'permitNumber'], [index, 'permitDate']]}
          rightText="Delivery"
        />
      ) : null}
      {/* <SelectExportLicense */}
      {/*  rest={item} */}
      {/*  label="Export License" */}
      {/*  prefix={formName} */}
      {/*  name={[index, 'exportLicense']} */}
      {/*  rightText="Delivery" */}
      {/* /> */}
      {dangerousGoods ? (
        <OrderUpload
          formName={formName}
          rest={item}
          label="Upload MSDS/SDS"
          prefix={formName}
          name={[index, 'msdsDocument']}
          rightText="Delivery"
          uploadProps={{
            maxCount: 1,
          }}
        />
      ) : null}

      <h3>
        <span>Additional services</span>
      </h3>
      <OrderSwitch
        rest={item}
        label="Temperature Logger"
        prefix={formName}
        name={[index, 'temperatureLogger']}
      />
      <OrderSwitch
        rest={item}
        label="Real Time Monitoring"
        prefix={formName}
        name={[index, 'realTimeMonitoring']}
      />
      <OrderSwitch
        rest={item}
        label="Special temperature mode"
        prefix={formName}
        name={[index, 'specialTemperatureMode']}
      />
      {specialTemperatureMode ? (
        <>
          <SelectSpecialTemperatureMode
            rest={item}
            label="Special temperature mode"
            prefix={formName}
            name={[index, 'temperatureMode']}
          />
          {temperatureMode === 'FROBO79 (Frozen Box UN 1845 -79 °C)' ? (
            <OrderInput
              rest={item}
              label="Net weight of Dry Ice"
              prefix={formName}
              name={[index, 'netWeightOfDryIce']}
              inputNumber
              inputNumberProps={{
                suffix: 'kg',
                controls: false,
              }}
              normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
