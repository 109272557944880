import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import CompaniesContent from '../../components/Pages/Companies';
import Menu from '../../components/Layout/Menu';

const { displayName } = config;

export default function Companies(): React.ReactNode | null {
  document.title = `${displayName}: Companies`;

  return (
    <Layout
      title="Companies"
      leftMenu={<Menu />}
    >
      <CompaniesContent />
    </Layout>
  );
}
