import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';
import { Company } from '../../../../hooks/api/company';

export interface CompanyContextProps {
  initialState: Company | null,
  setInitialState: (data: Company) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: Company) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const CompanyContext = createContext<CompanyContextProps>(defaultValue);

export interface CompanyProviderProps {
  children: React.ReactNode
}

function CompanyProvider({
  children,
}: CompanyProviderProps) {
  const [initialState, setInitialState] = useState<Company | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <CompanyContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export default CompanyProvider;

export const useCompanyContext = ():
  CompanyContextProps => React.useContext(CompanyContext);
