import React, { useEffect, useMemo } from 'react';

import {
  Breadcrumb, Button, Dropdown, MenuProps, Tag,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { ItemType as MenuItemType } from 'antd/es/menu/hooks/useItems';
import OrderForm from '../../Form';
import { useOrderContextForm } from '../../Form/context';
import {
  useOrderAcceptUpdate,
  useOrderCopy,
  useOrderDeclineUpdate,
  useOrderDelete, useOrderMarkAsDelivered, useOrderRestoreUpdate,
  useOrderSendTrackingLink, useOrderSendTemplateEmail,
} from '../../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useOrderContext } from '../context';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { OrderStatus } from '../../Adapter/enums';
import { getOrderStatusColor } from '../../index';

export function HeaderActions() {
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { order, clientOrderData } = useOrderContext();
  const {
    loadingFile,
    // isValid,
    orderUpdate,
    orderSave,
    orderProformaInvoicesGet,
    orderProformaInvoicesGenerate,
    ordersTransportDocumentsGet,
    ordersTransportDocumentsGenerate,
    generateProcess,
  } = useOrderContextForm();
  const { id } = useParams();
  const orderCopy = useOrderCopy();
  const orderDelete = useOrderDelete();
  const orderSendTrackingLink = useOrderSendTrackingLink(id);
  const orderAcceptUpdate = useOrderAcceptUpdate(id);
  const orderDeclineUpdate = useOrderDeclineUpdate(id);
  const orderMarkAsDelivered = useOrderMarkAsDelivered(id);
  const orderRestoreUpdate = useOrderRestoreUpdate(id);
  const orderSendTemplateEmail = useOrderSendTemplateEmail(id);

  useMessageError([
    orderDeclineUpdate,
    orderAcceptUpdate,
    orderRestoreUpdate,
    orderCopy,
    orderDelete,
    orderSendTrackingLink,
    orderMarkAsDelivered,
    orderSendTemplateEmail,
  ]);
  useMessageSuccess([orderAcceptUpdate], 'Accept order successfully');
  useMessageSuccess([orderCopy], 'Duplicate created successfully');
  useMessageSuccess([orderDelete], 'Order deleted successfully');
  useMessageSuccess([orderRestoreUpdate], 'Restore order successfully');
  useMessageSuccess([orderSendTrackingLink], 'Tracking link sent successfully');
  useMessageSuccess([orderDeclineUpdate], 'Decline order successfully');
  useMessageSuccess([orderMarkAsDelivered], 'Mark as delivered successfully');
  useMessageSuccess([orderSendTemplateEmail], 'Email with template sent successfully');

  useEffect(() => {
    if ((!orderAcceptUpdate.error || !orderDeclineUpdate.error || !orderRestoreUpdate.error)
      || (orderMarkAsDelivered.data && !orderMarkAsDelivered.error && !orderMarkAsDelivered.loading)
      || (orderDeclineUpdate.data && !orderDeclineUpdate.error && !orderDeclineUpdate.loading)
    ) {
      order.fetch();
    }
  }, [
    orderAcceptUpdate.response,
    orderDeclineUpdate.response,
    orderRestoreUpdate.response,
    orderMarkAsDelivered.response,
  ]);

  useEffect(() => {
    if (!orderCopy.error && !orderCopy.loading && orderCopy.data) {
      navigate(`/orders/${orderCopy.data.id}`); // /edit
    }
  }, [orderCopy.data]);

  const items = useMemo<MenuProps['items']>(() => {
    const template: { [key: string]: MenuItemType } = {
      DGAssistant: {
        key: 'Open in DGAssistant',
        label: 'Open in DGAssistant',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();

          window.open(
            `https://emea.dgassistant.com/Transport/Consignments/ConsignmentCreate.aspx?command=Update&con_id=${
              clientOrderData?.conId
            }&isforproducts=True`,
          );
        },
      },
      copy: {
        key: 'Make a copy',
        label: 'Make a copy',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderCopy.fetch({ id: id || '' });
        },
      },
      delete: {
        key: 'Delete',
        label: 'Delete',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete order?',
            content: (
              <span>
                Are you sure you want to delete order
                {' '}
                <b>{clientOrderData?.delivery?.orderNumber}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => orderDelete.fetch(id).then((res) => {
              if (res?.success) {
                navigate('/orders');
              }
            }),
          });
        },
        danger: true,
      },
      resend: {
        key: 'Resend Tracking link',
        label: 'Resend Tracking link',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderSendTrackingLink.fetch();
        },
      },
      markAsDelivered: {
        key: 'Mark as delivered',
        label: 'Mark as delivered',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderMarkAsDelivered.fetch();
        },
      },
    };

    switch (order?.data?.status) {
      case OrderStatus.NEW:
        return [
          template.copy,
          template.delete,
        ];

      case OrderStatus.IN_PROGRESS:
        return [
          template.DGAssistant,
          template.copy,
          template.delete,
        ];

      case OrderStatus.PROCESSING:
        return [
          template.DGAssistant,
          template.copy,
        ];

      case OrderStatus.DECLINED:
        return [
          template.copy,
          template.delete,
        ];

      case OrderStatus.SHIPPED_OUT:
        return [
          template.DGAssistant,
          template.resend,
          template.markAsDelivered,
          template.copy,
        ];
      case OrderStatus.ON_THE_WAY:
        return [
          template.DGAssistant,
          template.resend,
          template.markAsDelivered,
          template.copy,
        ];
      case OrderStatus.REJECTED:
        return [
          template.DGAssistant,
          template.copy,
          template.delete,
        ];

      case OrderStatus.DELIVERED: return [
        template.DGAssistant,
        template.copy,
        template.delete,
      ];

      default: return [];
    }
  }, [order]);

  return (
    <Actions>
      {clientOrderData?.status ? (
        <Tag color={getOrderStatusColor(clientOrderData?.status)} style={{ width: 'auto' }}>
          {clientOrderData?.status}
        </Tag>
      ) : null}

      {clientOrderData?.status === OrderStatus.DECLINED ? (
        <Button
          danger
          loading={orderRestoreUpdate.loading}
          onClick={(e) => {
            e.preventDefault();
            orderRestoreUpdate.fetch();
          }}
        >
          Restore
        </Button>
      ) : null}
      {clientOrderData && [
        OrderStatus.NEW,
        OrderStatus.IN_PROGRESS,
      ]
        .includes(clientOrderData?.status) ? (
          <Button
            danger
            loading={orderDeclineUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderDeclineUpdate.fetch();
            }}
          >
            Decline
          </Button>
        ) : null}

      {clientOrderData && [
        OrderStatus.NEW,
      ]
        .includes(clientOrderData?.status) ? (
          <Button
            type="primary"
            loading={orderAcceptUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderAcceptUpdate.fetch();
            }}
          >
            Accept
          </Button>
        ) : null}

      <Button
        loading={orderProformaInvoicesGet.loading || !!generateProcess}
        onClick={(e) => {
          e.preventDefault();
          orderProformaInvoicesGenerate();
        }}
      >
        Generate Proforma Invoice
      </Button>
      <Button
        loading={ordersTransportDocumentsGet.loading || !!generateProcess}
        onClick={(e) => {
          e.preventDefault();
          ordersTransportDocumentsGenerate();
        }}
      >
        Generate Transport Documents
      </Button>
      {/* TODO make sure is need to show this generate buttons */}

      <Button
        type="primary"
        // disabled={!isValid}
        loading={orderUpdate.loading || loadingFile}
        onClick={orderSave}
      >
        Save
      </Button>

      <Dropdown
        menu={{
          items: [{
            key: 'Send the order template',
            label: 'Send the order template',
            onClick: ({ domEvent }) => {
              domEvent.preventDefault();
              orderSendTemplateEmail.fetch();
            },
          }, ...(items || [])],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          loading={
            orderCopy.loading
            || orderDelete.loading
            || orderAcceptUpdate.loading
            || orderDeclineUpdate.loading
            || orderMarkAsDelivered.loading
            || orderSendTrackingLink.loading
            || orderSendTemplateEmail.loading
          }
          icon={<EllipsisOutlined />}
          style={{ transform: 'translate(0px, 1.5px)' }}
        />
      </Dropdown>

      {contextHolder}
    </Actions>
  );
}

export default function OrderEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { clientOrderData } = useOrderContext();

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/orders">Orders</NavLink>,
          },
          {
            title: (
              <NavLink to={`/orders/${id}`}>
                {`Order${clientOrderData ? ` №${clientOrderData?.delivery?.orderNumber}` : ''}`}
              </NavLink>
            ),
          },
          {
            title: 'Edit order',
          },
        ]}
      />

      <OrderForm />
    </>
  );
}
