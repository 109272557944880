import {
  DefaultFetchError,
  FetchGetId,
  useFetchGetId,
} from '../fetch';

export type DeliveryServiceType = 'dhl' | 'fedex' | 'schenker' | 'other';

export enum TrackingMethod {
  DHL = 'DHL',
  Fedex = 'Fedex',
  DBSchenker = 'DBSchenker',
}

export interface TrackingInfo {
  type: TrackingMethod;
  shipments: Array<{
    address: {
      name: string;
      countryCode: string;
    };
    description?: string;
    status: string;
    timestamp: string;
  }>;
}

export const useOrderTrackingDHLGet = <DD = TrackingInfo>(
  id?: string,
): FetchGetId<
  TrackingInfo,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/dhl',
    id,
    {
      autoStart: !!id,
    },
  );

export const useOrderTrackingFedexGet = <DD = TrackingInfo>(
  id?: string,
): FetchGetId<
  TrackingInfo,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/fedex',
    id,
    {
      autoStart: !!id,
    },
  );

export const useOrderTrackingDBSchenkerGet = <DD = TrackingInfo>(
  id?: string,
): FetchGetId<
  TrackingInfo,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/dbschenker',
    id,
    {
      autoStart: !!id,
    },
  );
