import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Order, useOrderGetById } from '../../../../hooks/api/order';
import { DefaultFetchError, FetchGetId } from '../../../../hooks/fetch';
import {
  ClientData, getClientData,
} from '../Adapter';
import { useMessageError } from '../../../../hooks/common';

export type Mode = 'view' | 'edit' | 'create';

export interface OrderContextProps {
  order: FetchGetId<
    Order,
    DefaultFetchError,
    unknown,
    Order
  >,
  mode: Mode,
  clientOrderData: ClientData | null,
  handleClientOrderData: (data: Order | null) => void,
}

const defaultValue: OrderContextProps = {
  order: {} as FetchGetId<
    Order,
    DefaultFetchError,
    unknown,
    Order
  >,
  mode: 'edit',
  clientOrderData: null,
  handleClientOrderData: () => undefined,
};

export const OrderContext = createContext<OrderContextProps>(defaultValue);

export interface OrderProviderProps {
  children: React.ReactNode;
  mode: Mode;
}

function OrderProvider({
  children, mode,
}: OrderProviderProps) {
  const { id } = useParams();
  const [clientOrderData, setClientOrderData] = useState<ClientData | null>(null);
  const orderGetById = useOrderGetById(id);

  // useEffect(() => {
  //   if (!orderGetById.loading && !orderGetById.error && id) {
  //     orderGetById.fetch(undefined, id);
  //   }
  // }, [id]);

  useMessageError([orderGetById]);

  useEffect(() => {
    if (orderGetById.data && !orderGetById.error && !orderGetById.loading) {
      setClientOrderData(getClientData(orderGetById.data, mode));
    }
  }, [orderGetById.data]);

  const handleClientOrderData = (orderData: Order | null) => {
    if (orderData) {
      setClientOrderData(getClientData(orderData, mode));
    }
  };

  return (
    <OrderContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        mode,
        order: orderGetById,
        clientOrderData,
        handleClientOrderData,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export default OrderProvider;

export const useOrderContext = ():
  OrderContextProps => React.useContext(OrderContext);
