import React, { useEffect } from 'react';
import {
  Breadcrumb, Button, Spin, Tag,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Orders, { getOrderStatusColor } from '../../Orders';
import { useCompanyDelete, useCompanyGetById } from '../../../../hooks/api/company';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';

import styles from './index.module.scss';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const companyDelete = useCompanyDelete(id || 'undefined');

  useMessageError([companyDelete]);
  useMessageSuccess([companyDelete], 'Company deleted successfully');

  useEffect(() => {
    if (!companyDelete.error && !companyDelete.loading && companyDelete.data) {
      navigate('../companies');
    }
  }, [
    companyDelete.error, companyDelete.loading, companyDelete.data,
  ]);

  return (
    <Actions>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();

          navigate('edit');
        }}
      >
        Edit
      </Button>
      <Button
        danger
        loading={companyDelete.loading}
        onClick={(e) => {
          e.preventDefault();

          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete company?',
            content: (
              <span>
                Are you sure you want to delete company?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => companyDelete.fetch(),
          });
        }}
      >
        Delete
      </Button>

      {contextHolder}
    </Actions>
  );
}

export default function CompanyView(): React.ReactNode | null {
  const { id } = useParams();
  const companyGetById = useCompanyGetById(id);
  const {
    companyName,
    contactName,
    phone,
    email,
    country,
    address1,
    address2,
    postalCode,
    city,
    state,
    tinNumber,
    registerNumber,
    status = 'active',
    createdAt,
  } = companyGetById.data || {};

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/companies">Companies</NavLink>,
          },
          {
            title: 'View company',
          },
        ]}
      />

      <div className={styles.company}>
        <div className={styles.info}>
          <div>
            <h4>
              Contact
            </h4>
            {companyName ? (
              <div>{companyName}</div>
            ) : null}
            {contactName ? (
              <div>{contactName}</div>
            ) : null}
            {phone ? (
              <a href={`tel:${phone}`}>{phone}</a>
            ) : null}
            {email ? (
              <a href={`mailto:${email}`}>{email}</a>
            ) : null}
          </div>
          <div>
            <h4>
              Address
            </h4>
            {address1 || address2 ? (
              <div>{[address1, address2].join(', ')}</div>
            ) : null}
            {city ? (
              <div>{city}</div>
            ) : null}
            {postalCode || state ? (
              <div>
                {state}
                {' '}
                {postalCode}
              </div>
            ) : null}
            {country ? (
              <div>{country}</div>
            ) : null}
          </div>
          <div>
            <h4>
              TIN number
            </h4>
            {tinNumber ? (
              <div>{tinNumber}</div>
            ) : null}
            <h4>
              Register number
            </h4>
            {registerNumber ? (
              <div>{registerNumber}</div>
            ) : null}
          </div>
          <div>
            <h4>
              Date added
            </h4>
            {createdAt ? (
              <div>
                {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
              </div>
            ) : null}
            <br />
            {status ? (
              <div>
                <Tag color={getOrderStatusColor(status)}>
                  {status}
                </Tag>
              </div>
            ) : null}
          </div>

          {companyGetById.loading ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : null}
        </div>

        <Orders companyId={id} />
      </div>
    </>
  );
}
