import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import AccountContent, { HeaderActions } from '../../components/Pages/Account';
import Menu from '../../components/Layout/Menu';
import AccountProvider from '../../components/Pages/Account/context';

const { displayName } = config;

export default function Account(): React.ReactNode | null {
  document.title = `${displayName}: Account`;

  return (
    <AccountProvider>
      <Layout
        title="Account"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <AccountContent />
      </Layout>
    </AccountProvider>
  );
}
