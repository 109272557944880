import React, { useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { Form } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import OrderSwitch from '../Common/Switch';
import {
  createRulesForAntd, createValidatorTextField, email,
} from '../../../../../utils/validations';
import OrderInput from '../Common/Input';
import { FormName, useOrderContextForm } from '../context';
import { FormWrapper } from '../index';
import SelectPlace from '../Common/SelectPlace';

import styles from '../index.module.scss';

function FullAddress({
  patch = '', formName, validationRules, disabledFields,
}: {
  patch?: string;
  formName: string;
  validationRules: Rule;
  disabledFields?: Record<string, boolean>; // 'country' | 'address' | 'address2' | 'postalCode' | 'city' | 'state'
}) {
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();

  return (
    <>
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['country']}
        form={form}
        label="Country"
        name={patch ? [patch, 'country'] : 'country'}
        rules={[validationRules, { required: true, message: <div /> }]}
        onSelect={(value) => {
          ['address', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
            form.setFieldValue(patch ? [patch, key] : key, undefined);
            if (!patch) {
              form.setFieldValue(['details', key], undefined);
            }
          });
          form.setFieldValue(['details', 'country'], value);
        }}
        disabled={disabledFields?.country}
      >
        <div className={styles.text}>
          DGA, PI, Delivery
        </div>
      </SelectPlace>
      <OrderInput
        style={{ display: 'none' }}
        name={patch ? [patch, 'countryCode'] : 'countryCode'}
      />
      <OrderInput
        style={{ display: 'none' }}
        name={patch ? [patch, 'stateCode'] : 'stateCode'}
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['address']}
        form={form}
        label="Address"
        name={patch ? [patch, 'address'] : 'address'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <OrderInput
        label="Address2"
        name={patch ? [patch, 'address2'] : 'address2'}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['postal_code']}
        form={form}
        label="Postal code"
        name={patch ? [patch, 'postalCode'] : 'postalCode'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['locality']}
        form={form}
        label="City / Town"
        name={patch ? [patch, 'city'] : 'city'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['administrative_area_level_1']}
        form={form}
        label="Province / Region / State"
        name={patch ? [patch, 'state'] : 'state'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
    </>
  );
}

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);

interface ShipperProps extends FormName {
  titleAdditional: string;
  switchName: string;
}

const baseForm = {
  company: textFieldRequired,
  contactName: textFieldRequired,
  phone: textFieldRequired,
  email,
  country: textFieldRequired,
  address: textFieldRequired,
  address2: textField,
  postalCode: textFieldRequired,
  city: textFieldRequired,
  state: textFieldRequired,
};

export default function Shipper({ formName, titleAdditional, switchName }: ShipperProps): React.ReactNode | null {
  const { setValidator, forms: { [formName]: form } } = useOrderContextForm();

  const validationRules = useMemo(() => createRulesForAntd(yup.object().shape({
    ...baseForm,
    tin: textField,
    registerNumber: textField,
    reference: textField,
    details: yup.object({
      ...baseForm,
    }),
  }), form.getFieldsValue), [form]);

  useEffect(() => {
    setValidator(formName, () => {
      const {
        details: detailsData,
        isAddressDifferent,
        address2,
        registerNumber,
        tin,
        reference,
        ...mainData
      } = form.getFieldsValue();
      const { address2: detailAddress2, ...details } = detailsData || {};

      return !(Object.values(mainData).some((value) => value === '' || value === undefined)
        || Object.values(details).some((value) => value === '' || value === undefined)
        || form.getFieldsError().some(({ errors }) => errors.length));
    });
  }, [form]);

  const addressDifferent = Form.useWatch('isAddressDifferent', form);

  const countryMainWatch = Form.useWatch('country', form);

  return (
    <FormWrapper formName={formName} className={styles.wrapper}>
      <h3>
        <span>{formName}</span>
      </h3>

      <OrderInput
        label="Company"
        name="company"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <OrderInput
        label="Contact name"
        name="contactName"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Mobile number"
        name="phone"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Email"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <FullAddress formName={formName} validationRules={validationRules} />
      <OrderInput
        label="TIN number"
        name="tin"
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Register number"
        name="registerNumber"
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Reference"
        name="reference"
        rightText="PI, Delivery"
      />

      <h3>
        <span>{titleAdditional}</span>
      </h3>

      <OrderSwitch
        className={styles.noLabel}
        name="isAddressDifferent"
        rightText={false}
      >
        {switchName}
      </OrderSwitch>

      {addressDifferent ? (
        <>
          <OrderInput
            label="Company"
            name={['details', 'company']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="DGA, PI, Delivery"
          />
          <OrderInput
            label="Contact name"
            name={['details', 'contactName']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="PI, Delivery"
          />
          <OrderInput
            label="Mobile number"
            name={['details', 'phone']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="PI, Delivery"
          />
          <OrderInput
            label="Email"
            name={['details', 'email']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="DGA, PI, Delivery"
          />
          {/* TODO disable country, and prefill it from top form. Country cant be different in shipper this case. */}
          <FullAddress
            patch="details"
            formName={formName}
            validationRules={validationRules}
            disabledFields={{ country: !!countryMainWatch }}
          />
          <OrderInput
            label="Register number"
            name={['details', 'registerNumber']}
            rightText="PI, Delivery"
          />
        </>
      ) : null}
    </FormWrapper>
  );
}
