import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess, FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from '../fetch';
import { User } from '../../store/auth';

interface AdministratorsGetParams extends PagingParams {
  orderByColumn?: 'username' | 'firstName';
}

export const useAdministratorsGet = <DD = AdministratorsTableData>(
  decorateData?: (data: PagingDataResponse<User>) => DD,
): FetchGet<
  PagingDataResponse<User>,
  AdministratorsGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'users',
    {
      autoStart: false,
      startStateLoading: false,
      decorateData,
    },
  );

export interface UserTable extends Omit<User, 'firstName' | 'lastName' | 'settings' | 'phone' | 'role'> {
  key: string;
  fullName: string;
}

export interface AdministratorsTableData {
  data: UserTable[];
  total: number;
}

export const useAdministratorsTableGet = () => useAdministratorsGet<AdministratorsTableData>(
  ({ data, meta }: PagingDataResponse<User>): AdministratorsTableData => ({
    data: data.map(({
      fullName, settings, ...item
    }: User): UserTable => ({
      key: item.id,
      fullName,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useAdministratorGetById = <DD = User>(
  id?: string,
  decorateData?: (data: User) => DD,
): FetchGetId<
  User,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'users',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface AdministratorCreateParams {
  fullName: string;
  email: string;
}

export const useAdministratorCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, AdministratorCreateParams> => (
  useFetchCreate('users')
);

export type AdministratorUpdateParams = AdministratorCreateParams

export const useAdministratorUpdate = (id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, AdministratorUpdateParams> => (
  useFetchUpdate('users', id)
);

export interface AdministratorSendInvitationParams {
  fullName: string;
  email: string;
}

export const useAdministratorSendInvitation = (id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, AdministratorSendInvitationParams
> => (
  useFetchUpdate(id ? `users/${id}/invitation` : 'users')
);

export const useAdministratorResetPassword = (id?: string): FetchGetId<User> => (
  useFetchGetId('users', id, { autoStart: false }));

export const useAdministratorDelete = (id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete('users', id)
);

export interface AdministratorChangePasswordParams {
  currentPassword: string;
  newPassword: string;
}

export const useAdministratorChangePassword = (): FetchCreate<
  FetchSuccess, DefaultFetchError, AdministratorChangePasswordParams> => (
  useFetchUpdate('users/update-password')
);
