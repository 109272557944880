import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';
import { ResponseForSelect } from './order';

export interface Company extends CompanyCreateParams {
  id: string;
  status: string;
  createdAt: string;
}

interface CompaniesGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useCompaniesGet = <DD = CompaniesTableData>(
  decorateData?: (data: PagingDataResponse<Company>) => DD,
): FetchGet<
  PagingDataResponse<Company>,
  CompaniesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'companies',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface CompaniesAll {
  id: string;
  companyName: string;
}

export const useCompaniesGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: CompaniesAll[]) => DD) =
  (data) => data.map(({ id, companyName }) => ({
    value: id, label: companyName,
  })) as DD,
): FetchGet<
  CompaniesAll[],
  CompaniesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'companies/plain',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'companies/all',
      cacheLifetime: 60000,
    },
  );

export interface CompanyTable extends Company {
  key: string;
}

export interface CompaniesTableData {
  data: CompanyTable[];
  total: number;
}

export const useCompaniesTableGet = () => useCompaniesGet<CompaniesTableData>(
  ({ data, meta }: PagingDataResponse<Company>): CompaniesTableData => ({
    data: data.map((item: Company): CompanyTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useCompanyGetById = <DD = Company>(
  id?: string,
  decorateData?: (data: Company) => DD,
): FetchGetId<
  Company,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'companies',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface CompanyCreateParams {
  contactName: string;
  // participantType: string;
  companyName: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tinNumber: string;
  registerNumber: string;
}

export const useCompanyCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, CompanyCreateParams> => (
  useFetchCreate('companies')
);

export type CompanyUpdateParams = Company;

export const useCompanyUpdate = (id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, CompanyUpdateParams> => (
  useFetchUpdate('companies', id)
);

export const useCompanyDelete = (id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete('companies', id)
);

export const useParticipantTypeGet = (): FetchGet<string[]> => useFetchGet('companies/participant-types');
