import React, {
  useCallback, useEffect, useState,
} from 'react';

import {
  Button, Collapse, CollapseProps, Form, Input,
} from 'antd';
import clsx from 'clsx';
import TextArea from 'antd/es/input/TextArea';
import { ReloadOutlined } from '@ant-design/icons';
import { ViewButton, ViewContent } from './view';
import { CreateButton, CreateContent } from './create';
import { EditButton, EditContent } from './edit';
import { FormName, useOrderContextForm } from '../../context';
import { useOrderContext } from '../../../View/context';
import {
  ClientDataGood, ClientDataPackages, EnumPackageKind, PackageItem, PackageKindType,
} from '../../../Adapter';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';

import styles from './index.module.scss';

const calcTotalPackages = (packagesItems: ClientDataPackages[]) => (packagesItems || []).reduce((total, curr) => (
  total + (curr?.quantity || 1)
), 0);

const calcTotalProducts = (packagesItems: ClientDataPackages[]) => (packagesItems || []).reduce((total, curr) => (
  total + calcSinglePackageProduct(curr.items, curr.quantity)
  // (curr?.items || []).reduce((totalItems, currItem) => (
  //   totalItems + (currItem.quantity || 0)
  // ), 0)) * (curr?.quantity || 1)
), 0);

const calcSinglePackageProduct = (packageItems: PackageItem[], quantity: number) => (
  (packageItems || []).reduce((totalItems, currItem) => (
    totalItems + (currItem.quantity || 0)
  ), 0) * (quantity || 1)
);

const calcTotalWeight = (packagesItems: ClientDataPackages[]) => (packagesItems || []).reduce((total, curr) => (
  total + (curr?.weight || 0)
), 0);

const calcTotalValue = (packagesItems: ClientDataPackages[]) => (packagesItems || []).reduce((total, curr) => (
  total + ((curr?.value || 0) * (curr?.quantity || 1))
), 0);

export interface ButtonProps extends FormName {
  item: ClientDataPackages;
  index: number;
  remove: () => void;
  formName: string;
}

export type ContentProps = ButtonProps

function CollapseQuantity({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const quantityWatch = Form.useWatch([formName, index, 'quantity'], form) || '';

  return quantityWatch ? `${quantityWatch} x` : '';
}

function CollapseTotalWeight({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const weightWatch = Form.useWatch([formName, index, 'weight'], form) || '';
  const quantityWatch = Form.useWatch([formName, index, 'quantity'], form) || '';

  return weightWatch ? `${weightWatch * (quantityWatch || 1)} kg` : '';
}

function CollapseKind({ formName, index }: { index: number } & FormName) {
  // const { forms: { [formName]: form } } = useOrderContextForm();
  // const kindWatch: PackageKindType = (Form.useWatch([formName, index, 'kind'], form) || '');

  return 'Own packaging';
  // return EnumPackageKind[kindWatch];
}

function CollapseCount({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const itemsWatch: PackageItem[] = Form.useWatch([formName, index, 'items'], form) || [];
  const quantityWatch = Form.useWatch([formName, index, 'quantity'], form) || '';

  return itemsWatch?.length > 0 ? `${calcSinglePackageProduct(itemsWatch, quantityWatch)} product(s)` : '';
}

function CollapsePrice({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const inputWatch = Form.useWatch([formName, index, 'value'], form) || '';
  const currencyWatch = Form.useWatch([formName, index, 'currency'], form) || '';
  const quantityWatch = Form.useWatch([formName, index, 'quantity'], form) || '';

  return inputWatch ? `${(inputWatch || 0) * (quantityWatch || 1)} ${currencyWatch}` : '';
}

function CollapseSizes({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const length = Form.useWatch([formName, index, 'length'], form);
  const width = Form.useWatch([formName, index, 'width'], form);
  const height = Form.useWatch([formName, index, 'height'], form);

  if (length) {
    return `${length || '-'} x ${width || '-'} x ${height || '-'} cm`;
  }

  return '';
}

interface ProductProps extends FormName {
  list?: ClientDataPackages[];
  update?: () => void;
}

function PackagesList({
  list, update,
  formName = 'products',
}: ProductProps) {
  const { product } = useSearchParams()[2];
  const { mode } = useOrderContext();
  const {
    forms: { packages: form }, goodsForm,
  } = useOrderContextForm();
  const [currentList, setCurrentList] = useState(list || []);
  const [items, setItems] = useState<CollapseProps['items']>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>('0');
  const formList: ClientDataPackages[] = Form.useWatch(['packages'], form);
  const goodsWatch: ClientDataGood[] = Form.useWatch(['goods'], goodsForm) || [];

  useEffect(() => {
    /** When goods being deleted we clear it also from all packages items. */
    if (formList) {
      const goodsIds = goodsWatch.map((i) => (i?.id)).filter((i) => !!i) as string[];

      const newValue = (formList || []).map((packageItem) => {
        const newItems = (packageItem?.items || []).filter((item) => (
          goodsIds.includes(item.goodsItem?.id || '')
        ));

        /** If after deleting product there's no items in package - reset weight & price */
        if (newItems.length === 0) {
          return {
            ...packageItem,
            weight: 0,
            value: 0,
            items: newItems,
          };
        }

        return {
          ...packageItem,
          items: newItems,
        };
      });

      form.setFieldValue(['packages'], newValue);
    }
  }, [goodsWatch?.length]);

  const generateSummaryFromNames = () => {
    const newValue = goodsWatch?.map((goodsItem) => goodsItem.name)?.join(', ') || '';

    form.setFieldValue('packageSummary', newValue);
  };

  const createItem = useCallback((item: ClientDataPackages, index: number, remove: () => void) => {
    const label = (
      <div className={styles.label}>
        <div className={styles.x}>
          {mode === 'view'
            ? item.quantity || ''
            : <CollapseQuantity formName={formName} index={index} />}
        </div>
        <div>
          {mode === 'view'
            ? EnumPackageKind[item?.kind || '']
            : <CollapseKind formName={formName} index={index} />}
        </div>
        <div className={styles.alignRight}>
          {mode === 'view'
            ? `${item.height || '-'} x ${item.width || '-'} x ${item.length} cm`
            : <CollapseSizes formName={formName} index={index} />}
        </div>
        <div className={styles.alignRight}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {mode === 'view'
            ? (item?.items?.length ? `${item.items.length} product(s)` : '')
            : <CollapseCount formName={formName} index={index} />}
        </div>
        <div className={styles.alignRight}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {mode === 'view'
            ? (item?.value ? `${item.value} ${item.currency}` : '')
            : <CollapsePrice formName={formName} index={index} />}
        </div>
        <div className={styles.end}>
          {mode === 'view'
            ? item?.items?.reduce((total, curr) => (
              total + Number(curr.goodsItem?.net || 0)
            ), 0) || '-'
            : <CollapseTotalWeight formName={formName} index={index} />}
        </div>
      </div>
    );

    switch (mode) {
      case 'create':
        return {
          forceRender: true,
          key: index,
          label,
          children: <CreateContent formName={formName} item={item} index={index} remove={remove} />,
          extra: <CreateButton formName={formName} item={item} index={index} remove={remove} />,
        };
      case 'edit': return {
        forceRender: true,
        key: index,
        label,
        children: <EditContent formName={formName} item={item} index={index} remove={remove} />,
        extra: <EditButton formName={formName} item={item} index={index} remove={remove} />,
      };
      case 'view': return {
        forceRender: true,
        key: index,
        label,
        children: <ViewContent formName={formName} item={item} index={index} remove={remove} />,
        extra: <ViewButton
          formName={formName}
          item={item}
          index={index}
          remove={() => {
            if (update) {
              update();
            }
          }}
        />,
      };
      default: return {};
    }
  }, []);

  useEffect(() => {
    if (list) {
      setCurrentList(list);
    }
  }, [list]);

  useEffect(() => {
    if (formList) {
      setCurrentList(formList);
    }
  }, [formList]);

  useEffect(() => {
    setItems(currentList?.map((item, index) => createItem(
      item,
      index,
      () => currentList.filter(((_, id) => id !== index)),
    )));
  }, [currentList]);

  return (
    <>
      {mode === 'view' && items && items.length > 0 ? (
        <Collapse
          className={styles.collapse}
          size="small"
          items={items}
        />
      ) : null}

      {mode === 'edit' || mode === 'create' ? (
        <Form.List
          name={formName}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }) => (
                <React.Fragment key={key}>
                  <Collapse
                    defaultActiveKey={[product as string]}
                    className={styles.collapse}
                    size="small"
                    accordion
                    items={[createItem(rest as ClientDataPackages, name, () => remove(name))]}
                    onChange={(keyCollapse) => setActiveKey(keyCollapse)}
                    activeKey={activeKey}
                  />
                  <Form.Item hidden name={[name, 'id']}>
                    <Input />
                  </Form.Item>
                </React.Fragment>
              ))}
              <div className={styles.create}>
                <Button onClick={(e) => {
                  e.preventDefault();
                  add({ quantity: 1 });
                  setActiveKey([fields?.length.toString()]);
                }}
                >
                  Add
                </Button>
              </div>
            </>
          )}
        </Form.List>
      ) : null}

      <div className={clsx(styles.calc, { [styles.empty]: items && items.length === 0 && mode === 'view' })}>
        {/* <div className={styles.units}>
          <b>{(currentList || []).reduce((accumulator, value) => accumulator + (value.quantity || 0), 0)}</b>
          unit(s)
        </div> */}
        <div />

        <div className={styles.totalPackages}>
          <div>
            <p>
              Total packages:
              <b>
                {' '}
                {calcTotalPackages(formList)}
              </b>
            </p>
          </div>

          <div>
            <p>
              Total products:
              <b>
                {' '}
                {calcTotalProducts(formList)}
              </b>
            </p>
          </div>

          <div>
            <p>
              Total weight:
              <b>
                {` ${(calcTotalWeight(formList) || 0).toFixed(3)} kg`}
              </b>
            </p>
          </div>

          <div>
            <p>
              Total value:
              <b>
                {` ${calcTotalValue(formList)} ${formList?.[0]?.currency || ''}`}
              </b>
            </p>
          </div>
        </div>

      </div>

      <div style={{ padding: '12px' }}>
        <Form.Item
          name="packageSummary"
          label="Shipment summary"
          extra={(
            <Button
              icon={<ReloadOutlined />}
              onClick={generateSummaryFromNames}
            >
              Sync
            </Button>
          )}
          className="form-item-default-extra"
        >
          <TextArea
            rows={3}
            placeholder="Provide details in English only"
            showCount
            maxLength={70}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default PackagesList;
