import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useTransportGetAll } from '../../../../../../hooks/api/order';

function SelectModeTransport({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useTransportGetAll();

  return (
    <OrderSelect fetch={dualUseGet} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectModeTransport;
