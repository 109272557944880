import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
  App,
  Button, Checkbox, Form, Input, InputNumber,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { FormName, useOrderContextForm } from '../context';
import OrderUpload from '../Common/Upload';
import { FormWrapper } from '../index';
import OrderInput from '../Common/Input';
import { Goods, OrdersTransportDocument } from '../../../../../hooks/api/order';

import commonStyles from '../index.module.scss';
import styles from './index.module.scss';

// import { createRulesForAntd, createValidatorTextField } from '../../../../../utils/validations';
// export const validationEmergencyPhone = yup.object().shape({
//   emergencyPhoneNumber: yup.string()
//     .max(250, 'Cannot be more than 250 characters')
//     .min(3, 'Must be at least 3 characters'),
// });
// const validationRules = createRulesForAntd(validationEmergencyPhone);

type DocumentsProps = FormName;

export default function Documents({ formName }: DocumentsProps): React.ReactNode | null {
  const { message } = App.useApp();
  const {
    forms: {
      documents: documentsForm,
      general: generalForm,
      goods: goodForm,
    },
    orderProformaInvoicesGet,
    ordersTransportDocumentsGet,
    generateProcess,
    ordersTransportDocumentsGenerate,
    orderProformaInvoicesGenerate,
  } = useOrderContextForm();
  const transport = Form.useWatch('modeOfTransport', generalForm);
  const goodsWatch: Goods[] = Form.useWatch('goods', goodForm) || [];

  const isGoodsHasDangerousMark = useMemo(() => (
    goodsWatch.some((item) => item?.dangerousGoods)
  ), [goodsWatch]);

  const iata = transport && transport.toString().trim().toLowerCase().includes('(iata)');
  const imdg = transport && transport.toString().trim().toLowerCase().includes('(imdg-imo)');

  useEffect(() => {
    if (ordersTransportDocumentsGet.data
      && !ordersTransportDocumentsGet.error
      && !ordersTransportDocumentsGet.loading
    ) {
      const oldList = documentsForm.getFieldValue('transportDocuments');
      const newList = ordersTransportDocumentsGet.data?.length ? ordersTransportDocumentsGet.data.map(
        (transportDocument) => ({
          id: transportDocument?.id,
          uid: transportDocument?.id,
          name: transportDocument?.originalName,
          status: 'done',
          loading: false,
          url: transportDocument?.location,
        }),
      ) : [];

      if (oldList?.fileList) {
        oldList.fileList?.forEach((file: OrdersTransportDocument) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (file && newList.includes(({ name }: OrdersTransportDocument) => name !== file.name)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newList.push(file);
          }
        });
      }

      documentsForm.setFieldValue('transportDocuments', {
        file: null,
        fileList: newList,
      });
    }
  }, [ordersTransportDocumentsGet.data]);

  useEffect(() => {
    if (orderProformaInvoicesGet.data
      && !orderProformaInvoicesGet.error
      && !orderProformaInvoicesGet.loading
    ) {
      documentsForm.setFieldValue('proformaInvoices', {
        file: null,
        fileList: [{
          id: orderProformaInvoicesGet.data?.id,
          uid: orderProformaInvoicesGet.data?.id,
          name: orderProformaInvoicesGet.data?.originalName,
          status: 'done',
          loading: false,
          url: orderProformaInvoicesGet.data?.location,
        }],
      });
    }
  }, [orderProformaInvoicesGet.data]);

  return (
    <FormWrapper formName={formName} className={clsx(commonStyles.wrapper, styles.document)}>
      <div className={styles.transportDocuments}>
        <div>
          <div className={styles.header}>
            <b>Dangerous Goods Document</b>
            {' '}
            <span>(DGA)</span>
          </div>
          <div className={styles.flexNoWrap}>
            <OrderUpload
              multiple
              formName={formName}
              name="transportDocuments"
              rightText={false}
              className={styles.upload}
            />
            <Button
              icon={<ReloadOutlined />}
              className={styles.generate}
              loading={ordersTransportDocumentsGet.loading || !!generateProcess}
              onClick={(e) => {
                e.preventDefault();
                if (!isGoodsHasDangerousMark) {
                  message.error(
                    'You can\'t generate dangerous goods documents without any dangerous goods selected',
                  );
                } else {
                  ordersTransportDocumentsGenerate();
                }
              }}
            >
              Generate
            </Button>
          </div>
        </div>
        <div>
          {imdg ? (
            <div className={styles.marginBottomNone}>
              <Form.Item name="IMDGGoodsDeclaration" valuePropName="checked">
                <Checkbox>IMO-IMDG Dangerous Goods Declaration</Checkbox>
              </Form.Item>
              {/* <Form.Item name="IMDGShowBlueStripes" valuePropName="checked" style={{ marginLeft: 24 }}>
                <Checkbox>Show Blue Stripes</Checkbox>
              </Form.Item> */}

              <Form.Item name="loadChecklist" valuePropName="checked">
                <Checkbox>Load Checklist</Checkbox>
              </Form.Item>
            </div>
          ) : (
            <>
              <div className={styles.marginBottomNone}>
                {iata ? (
                  <>
                    <Form.Item name="IATAMultimodalDangerous" valuePropName="checked">
                      <Checkbox>IATA Multimodal Dangerous Goods Declaration</Checkbox>
                    </Form.Item>
                    {/* <Form.Item name="IATAShowRedStripes" valuePropName="checked" style={{ marginLeft: 24 }}>
                      <Checkbox>Show Red Stripes</Checkbox>
                    </Form.Item> */}
                  </>
                ) : null}

                <Form.Item name="adrTransportDocument" valuePropName="checked">
                  <Checkbox>ADR Transport Document</Checkbox>
                </Form.Item>
                <Form.Item name="loadChecklist" valuePropName="checked">
                  <Checkbox>Load Checklist</Checkbox>
                </Form.Item>
                <Form.Item name="packageMarking" valuePropName="checked">
                  <Checkbox>Package Marking</Checkbox>
                </Form.Item>
                <Form.Item name="marksForAllPackages" valuePropName="checked" style={{ marginLeft: 24 }}>
                  <Checkbox disabled={iata}>Marks For All Packages</Checkbox>
                </Form.Item>
                <Form.Item name="packageMarkingCustom" valuePropName="checked">
                  <Checkbox>Package Marking (Small custom size)</Checkbox>
                </Form.Item>
                <Form.Item name="marksForAllPackagesSmall" valuePropName="checked" style={{ marginLeft: 24 }}>
                  <Checkbox>Marks For All Packages</Checkbox>
                </Form.Item>
              </div>
              <div className={styles.size}>
                <Form.Item name="width">
                  <InputNumber placeholder="Width (mm) Input" />
                </Form.Item>
                <Form.Item name="height">
                  <InputNumber placeholder="Height (mm) Input" />
                </Form.Item>
              </div>
            </>
          )}
          <div className={styles.emergencyPhone}>
            <Form.Item
              label="24h emergency number"
              name="emergencyPhoneNumber"
              // rules={[validationRules]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className={styles.proformaInvoice}>
        <div className={styles.header}>
          <b>Proforma Invoice</b>
          {' '}
          <span>(Delivery)</span>
        </div>
        <div className={styles.flexNoWrap}>
          <OrderUpload
            multiple={false}
            formName={formName}
            name="proformaInvoices"
            rightText={false}
            className={styles.upload}
          />
          <Button
            icon={<ReloadOutlined />}
            className={styles.generate}
            loading={orderProformaInvoicesGet.loading || !!generateProcess}
            onClick={(e) => {
              e.preventDefault();
              orderProformaInvoicesGenerate();
            }}
          >
            Generate
          </Button>
        </div>
        <div>
          <OrderInput
            style={{ marginTop: '16px' }}
            textarea
            textAreaProps={{
              placeholder: 'Please enter',
              rows: 5,
            }}
            name="invoiceNotes"
            rightText={false}
          />
        </div>
      </div>
      <div className={styles.additionalDocuments}>
        <div className={styles.header}>
          <b>Additional documents</b>
          {' '}
          <span>(Delivery)</span>
        </div>
        <OrderUpload
          formName={formName}
          name="additionalDocuments"
          rightText={false}
          className={styles.upload}
          multiple
        />
      </div>
      {/* <div className={styles.productDocuments}> */}
      {/*  <div className={styles.header}> */}
      {/*    <b>Product documents</b> */}
      {/*    {' '} */}
      {/*    <span>(Delivery)</span> */}
      {/*  </div> */}
      {/*  <div className={styles.list}> */}
      {/*    /!* eslint-disable-next-line @typescript-eslint/ban-ts-comment *!/ */}
      {/*    /!* @ts-ignore *!/ */}
      {/*    {goodForm.getFieldsValue()?.goods?.map(({ exportLicenseDocument, msdsDocument }, i) => ( */}
      {/*      <React.Fragment */}
      {/*        key={`goods_${exportLicenseDocument?.fileList?.length}_${msdsDocument?.fileList?.length}_${i + 1}`} */}
      {/*      > */}
      {/*        /!* eslint-disable-next-line @typescript-eslint/ban-ts-comment *!/ */}
      {/*        /!* @ts-ignore *!/ */}
      {/*        {exportLicenseDocument?.fileList?.map(({ url, name }) => ( */}
      {/*          <a key={url} href={url} target="_blank" rel="noreferrer"> */}
      {/*            {name} */}
      {/*          </a> */}
      {/*        ))} */}
      {/*        /!* eslint-disable-next-line @typescript-eslint/ban-ts-comment *!/ */}
      {/*        /!* @ts-ignore *!/ */}
      {/*        {msdsDocument?.fileList?.map(({ url, name }) => ( */}
      {/*          <a key={url} href={url} target="_blank" rel="noreferrer"> */}
      {/*            {name} */}
      {/*          </a> */}
      {/*        ))} */}
      {/*      </React.Fragment> */}
      {/*    ))} */}
      {/*  </div> */}
      {/*  /!* <OrderUpload *!/ */}
      {/*  /!*  formName={formName} *!/ */}
      {/*  /!*  name="productDocuments" *!/ */}
      {/*  /!*  rightText={false} *!/ */}
      {/*  /!*  className={styles.upload} *!/ */}
      {/*  /!* /> *!/ */}
      {/* </div> */}
    </FormWrapper>
  );
}
