export enum ModeOfTransport {
  ADR = 'Road Freight (ADR)',
  IATA = 'Air Freight (IATA)',
  IMDG = 'Sea Freight (IMDG-IMO)',
  RAIL = 'Rail',
  COURIER = 'Courier',
}

export enum DeliveryTerms {
  EXW = 'EXW (Ex Works)',
  FCA = 'FCA (Free Carrier)',
  CPT = 'CPT (Carriage Paid To)',
  CIP = 'CIP (Carriage and Insurance Paid To)',
  DAP = 'DAP (Delivered at Place)',
  DPU = 'DPU (Delivered at Place Unloaded)',
  DDP = 'DDP (Delivered Duty Paid)',
  FAS = 'FAS (Free Alongside Ship)',
  FOD = 'FOB (Free on Board)',
  CFR = 'SFR (Cost and Freight)',
  CIF = 'CIF (Cost, Insurance and Freight)',
}
export enum OrderStatus {
  NEW = 'New',
  DECLINED = 'Declined',
  IN_PROGRESS = 'In progress',
  PROCESSING = 'Processing',
  REJECTED = 'Rejected',
  SHIPPED_OUT = 'Shipped Out',
  ON_THE_WAY = 'On the way',
  DELIVERED = 'Delivered',
}

export enum DeliveryService {
  DHL = 'dhl',
  FEDEX = 'fedex',
  SCHENKER = 'schenker',
  OTHER = 'other',
}

export enum DeliveryServiceDisplayName {
  dhl = 'DHL',
  fedex = 'Fedex',
  schenker = 'DB Schenker',
  other = 'Other',
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

export enum CompanyNames {
  ZEPHYRE = 'Sephyre',
}

export enum PlacesNames {
  ZEPHYRE = 'Sephyre',
}

export enum Declarants {
  RAMON = 'Ramon Kobler - Safety Adviser',
}

export enum PackagingGroup {
  ONE = 'I (high danger)',
  TWO = 'II (moderate danger)',
}

export enum PackagingType {
  BOX = 'box',
  BARREL = 'barrel',
}

export enum InnerPackagingType {
  BOX = 'box',
  BARREL = 'barrel',
}

export enum Mass {
  KG = 'kg',
  LITER = 'L',
}

export enum Description {
  AMMONIUM = 'ammonium',
  BOMBS = 'bombs',
}

export enum PhysicalState {
  LIQUID = 'Liquid',
  SOLID = 'Solid',
  GAS = 'Gas',
}

export enum TemperatureMode {
  DRY_ICE = 'FROBO79 (Box, UN 1845 Dry Ice)',
  OTHER = 'other',
}
