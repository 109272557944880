import React, { useEffect, useMemo, useState } from 'react';

import { Form, Spin, Tabs } from 'antd';
import clsx from 'clsx';
import { FormProps } from 'rc-field-form/lib/Form';
import { FormName, useOrderContextForm } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import General from './General';
import Shipper from './Shipper';
import Products from './Products';
import Documents from './Documents';
import Delivery from './Delivery';
import { useOrderContext } from '../View/context';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { OrderStatus } from '../Adapter/enums';

import commonStyles from '../../../Common/Form/index.module.scss';
import styles from './index.module.scss';

export interface FormWrapperProps extends FormProps, FormName {
  children: React.ReactNode;
  disabled?: boolean;
}

export function FormWrapper({
  formName, children, disabled = false, ...props
}: FormWrapperProps) {
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      triggerValidationAllForm();
    }, 500);

    return () => clearTimeout(timeout);
  }, [trigger]);

  return (
    <Form
      name={formName}
      className={clsx(commonStyles.form, commonStyles.miniTopPadding, styles.order)}
      // @ts-ignore - unknown error
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={() => { // TODO check. if call triggerValidation slows inputs in form like 10x times.
        setTrigger((prevState) => prevState + 1);
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </Form>
  );
}

const tabs = [
  {
    forceRender: true,
    key: 'General data',
    label: 'General data',
    children: <General formName="general" />,
  },
  {
    forceRender: true,
    key: 'Shipper',
    label: 'Shipper',
    children: <Shipper
      formName="shipper"
      titleAdditional="Pickup request"
      switchName={"The Pickup address should differ from the Shipper's address"}
    />,
  },
  {
    forceRender: true,
    key: 'Consignee',
    label: 'Consignee',
    children: <Shipper
      formName="importer"
      titleAdditional="Delivery request"
      switchName={"The Delivery address should differ from the Importer's address"}
    />,
  },
  {
    forceRender: true,
    key: 'Products',
    label: 'Products',
    children: <Products formName="goods" />,
  },
  {
    forceRender: true,
    key: 'Documents',
    label: 'Documents',
    children: <Documents formName="documents" />,
  },
  {
    forceRender: true,
    key: 'Delivery',
    label: 'Delivery',
    children: <Delivery formName="delivery" />,
  },
];

export const isOrderTabsDisabled = (status: OrderStatus | undefined): boolean => {
  const inProgressStatuses: OrderStatus[] = [OrderStatus.PROCESSING, OrderStatus.SHIPPED_OUT,
    OrderStatus.ON_THE_WAY, OrderStatus.DELIVERED];

  return !!inProgressStatuses?.includes(status || OrderStatus.NEW);
};

const alwaysActiveTabs = new Set(['Documents', 'Delivery']);

export default function OrderForm(): React.ReactNode | null {
  const [_, setSearchParams, params] = useSearchParams();
  const { forms, setInitialState } = useOrderContextForm();
  const { order, clientOrderData } = useOrderContext();

  useEffect(() => {
    if (!order.error && !order.loading && order.data && clientOrderData) {
      forms.general?.setFieldsValue(clientOrderData.general);
      setInitialState('general', clientOrderData.general);

      forms.shipper?.setFieldsValue(clientOrderData.shipper);
      setInitialState('shipper', clientOrderData.shipper);

      forms.importer?.setFieldsValue(clientOrderData.importer);
      setInitialState('importer', clientOrderData.importer);

      forms.goods?.setFieldsValue(clientOrderData.goods);
      setInitialState('goods', clientOrderData.goods);

      forms.packages?.setFieldsValue(clientOrderData.packages);
      setInitialState('packages', clientOrderData.packages);

      forms.documents?.setFieldsValue(clientOrderData.documents);
      setInitialState('documents', clientOrderData.documents);

      forms.delivery?.setFieldsValue(clientOrderData.delivery);
      setInitialState('delivery', clientOrderData.delivery);
    }
  }, [clientOrderData, order.data]);

  /** If order has status Processing and further then show only delivery and documents tab */
  useEffect(() => {
    if (clientOrderData && isOrderTabsDisabled(clientOrderData.status)
      && params.tab !== 'Documents') {
      setSearchParams({ tab: 'Delivery' }, { replace: true });
    }
  }, [clientOrderData]);

  const updatedTabs = useMemo(() => tabs.map((tab) => ({
    ...tab,
    disabled: alwaysActiveTabs.has(tab.key) ? false : isOrderTabsDisabled(clientOrderData?.status),
  })), [tabs, clientOrderData]);

  return (
    <div
      className={clsx(commonStyles.form, commonStyles.miniTopPadding, styles.order)}
      style={{ padding: '6px 24px 24px' }}
    >
      <Tabs
        key="orderTabs"
        defaultActiveKey={(params.tab as string) || 'General data'}
        activeKey={(params.tab as string) || 'General data'}
        items={updatedTabs}
        onChange={(key) => setSearchParams({ tab: key })}
      />

      {order.loading ? (
        <div className={commonStyles.spin}>
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
