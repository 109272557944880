import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import OrderForm from '../Form';
import Actions from '../../../Common/Header/Actions';
import { useOrderContextForm } from '../Form/context';

export function HeaderActions() {
  const {
    loadingFile,
    // isValid,
    orderCreate,
    orderSave,
  } = useOrderContextForm();

  return (
    <Actions>
      <Button
        type="primary"
        // disabled={!isValid}
        loading={orderCreate.loading || loadingFile}
        onClick={orderSave}
      >
        Save
      </Button>
    </Actions>
  );
}

export default function OrderCreate(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/orders">Orders</NavLink>,
          },
          {
            title: 'Add order',
          },
        ]}
      />

      <OrderForm />
    </>
  );
}
