import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';

export interface AccountContextProps {
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const AccountContext = createContext<AccountContextProps>(defaultValue);

export interface AccountProviderProps {
  children: React.ReactNode
}

function AccountProvider({
  children,
}: AccountProviderProps) {
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <AccountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export default AccountProvider;

export const useAccountContext = ():
  AccountContextProps => React.useContext(AccountContext);
