import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  App,
  Breadcrumb, Button, Dropdown, MenuProps, Spin, Tag,
} from 'antd';
import {
  ClockCircleOutlined, EllipsisOutlined, ExclamationCircleFilled, PaperClipOutlined, ReloadOutlined,
} from '@ant-design/icons';
import { ItemType as MenuItemType } from 'antd/es/menu/hooks/useItems';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useOrderContext } from './context';
import Actions from '../../../Common/Header/Actions';
import Product from '../Form/Products/ProductList';
import Tracking from '../Tracking';
import {
  useOrderAcceptUpdate,
  useOrderCopy, useOrderDeclineUpdate,
  useOrderDelete, useOrderMarkAsDelivered, useOrderRestoreUpdate,
  useOrdersDownloadAllGet, useOrderSendTrackingLink,
  useOrderUpdate, useOrderSendTemplateEmail,
} from '../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import { getOrderStatusColor } from '../index';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import SelectCompany from '../Form/General/SelectCompany';
import { useOrderContextForm } from '../Form/context';
import { DeliveryServiceDisplayName, OrderStatus } from '../Adapter/enums';
import { isOrderTabsDisabled } from '../Form';

import styles from './index.module.scss';

export function HeaderActions() {
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { order, clientOrderData } = useOrderContext();
  const { id } = useParams();
  const orderCopy = useOrderCopy();
  const orderDelete = useOrderDelete();
  const orderAcceptUpdate = useOrderAcceptUpdate(id);
  const orderDeclineUpdate = useOrderDeclineUpdate(id);
  const orderRestoreUpdate = useOrderRestoreUpdate(id);
  const { ordersTransportDocumentsGet, orderProformaInvoicesGet } = useOrderContextForm();
  const orderSendTrackingLink = useOrderSendTrackingLink(id);
  const orderMarkAsDelivered = useOrderMarkAsDelivered(id);
  const orderSendTemplateEmail = useOrderSendTemplateEmail(id);

  useMessageError([
    orderCopy,
    orderDelete,
    orderAcceptUpdate,
    orderDeclineUpdate,
    orderRestoreUpdate,
    orderSendTrackingLink,
    orderMarkAsDelivered,
    orderSendTemplateEmail,
  ]);
  useMessageSuccess([orderAcceptUpdate], 'Accept order successfully');
  useMessageSuccess([orderDeclineUpdate], 'Decline order successfully');
  useMessageSuccess([orderRestoreUpdate], 'Restore order successfully');
  useMessageSuccess([orderCopy], 'Duplicate created successfully');
  useMessageSuccess([orderDelete], 'Order deleted successfully');
  useMessageSuccess([orderSendTrackingLink], 'Tracking link sent successfully');
  useMessageSuccess([orderMarkAsDelivered], 'Mark as delivered successfully');
  useMessageSuccess([orderSendTemplateEmail], 'Email with template sent successfully');

  useEffect(() => {
    if (orderDelete.response && !orderDelete.loading && !orderDelete.error) {
      navigate('/orders');
    }
  }, [orderDelete.response]);

  useEffect(() => {
    if ((!orderAcceptUpdate.error || !orderDeclineUpdate.error || !orderRestoreUpdate.error)
      || (orderMarkAsDelivered.data && !orderMarkAsDelivered.error && !orderMarkAsDelivered.loading)
    ) {
      order.fetch();
    }
  }, [
    orderAcceptUpdate.response,
    orderDeclineUpdate.response,
    orderRestoreUpdate.response,
    orderMarkAsDelivered.response,
  ]);

  useEffect(() => {
    if (!orderCopy.error && !orderCopy.loading && orderCopy.data) {
      navigate(`/orders/${orderCopy.data.id}/`); // /edit
      window.location.reload();
    }
  }, [orderCopy.data]);

  useEffect(() => {
    if (orderProformaInvoicesGet.data?.location) {
      try {
        // Automatically downloads files when data appears
        // window.location.assign(orderProformaInvoicesGet.data?.location);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [orderProformaInvoicesGet.data]);

  const items = useMemo<MenuProps['items']>(() => {
    const template: { [key: string]: MenuItemType } = {
      DGAssistant: {
        key: 'Open in DGAssistant',
        label: 'Open in DGAssistant',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();

          window.open(
            `https://emea.dgassistant.com/Transport/Consignments/ConsignmentCreate.aspx?command=Update&con_id=${
              clientOrderData?.conId
            }&isforproducts=True`,
          );
        },
      },
      copy: {
        key: 'Make a copy',
        label: 'Make a copy',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderCopy.fetch({ id: id || '' });
        },
      },
      delete: {
        key: 'Delete',
        label: 'Delete',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete order?',
            content: (
              <span>
                Are you sure you want to delete order
                {' '}
                <b>{clientOrderData?.delivery?.orderNumber}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => orderDelete.fetch(id),
          });
        },
        danger: true,
      },
      resend: {
        key: 'Resend Tracking link',
        label: 'Resend Tracking link',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderSendTrackingLink.fetch();
        },
      },
      markAsDelivered: {
        key: 'Mark as delivered',
        label: 'Mark as delivered',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderMarkAsDelivered.fetch();
        },
      },
    };

    switch (order?.data?.status) {
      case OrderStatus.NEW:
        return [
          template.copy,
          template.delete,
        ];

      case OrderStatus.IN_PROGRESS:
        return [
          template.DGAssistant,
          {
            key: 'Generate Transport Documents',
            label: 'Generate Transport Documents',
            onClick: ({ domEvent }) => {
              domEvent.preventDefault();
              ordersTransportDocumentsGet.fetch();
            },
          },
          {
            key: 'Generate Proforma Invoice',
            label: 'Generate Proforma Invoice',
            onClick: ({ domEvent }) => {
              domEvent.preventDefault();
              orderProformaInvoicesGet.fetch();
            },
          },
          template.copy,
          template.delete,
        ];

      case OrderStatus.PROCESSING:
        return [
          template.DGAssistant,
          template.copy,
        ];

      case OrderStatus.DECLINED:
        return [
          template.copy,
          template.delete,
        ];

      case OrderStatus.SHIPPED_OUT:
        return [
          template.DGAssistant,
          template.resend,
          template.markAsDelivered,
          template.copy,
        ];
      case OrderStatus.ON_THE_WAY:
        return [
          template.DGAssistant,
          template.resend,
          template.markAsDelivered,
          template.copy,
        ];
      case OrderStatus.REJECTED:
        return [
          template.DGAssistant,
          template.copy,
          template.delete,
        ];

      case OrderStatus.DELIVERED: return [
        template.DGAssistant,
        template.copy,
        template.delete,
      ];

      default: return [];
    }
  }, [order]);

  return (
    <Actions>
      {clientOrderData?.status === OrderStatus.DECLINED ? (
        <Button
          danger
          loading={orderRestoreUpdate.loading}
          onClick={(e) => {
            e.preventDefault();
            orderRestoreUpdate.fetch();
          }}
        >
          Restore
        </Button>
      ) : null}

      {clientOrderData && [
        OrderStatus.NEW,
        OrderStatus.IN_PROGRESS,
      ]
        /* && ![
          OrderStatus.DELIVERED,
          OrderStatus.SHIPPED_OUT,
          OrderStatus.REJECTED,
          OrderStatus.ON_THE_WAY,
          OrderStatus.PROCESSING,
          OrderStatus.DECLINED,
        ] */
        .includes(clientOrderData?.status) ? (
          <Button
            danger
            loading={orderDeclineUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderDeclineUpdate.fetch();
            }}
          >
            Decline
          </Button>
        ) : null}

      {clientOrderData && [
        OrderStatus.NEW,
      ]
      /* && ![
        OrderStatus.SHIPPED_OUT,
        OrderStatus.PROCESSING,
        OrderStatus.DECLINED,
        OrderStatus.IN_PROGRESS,
        OrderStatus.REJECTED,
        OrderStatus.ON_THE_WAY,
        OrderStatus.DELIVERED,
      ] */
        .includes(clientOrderData?.status) ? (
          <Button
            type="primary"
            loading={orderAcceptUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderAcceptUpdate.fetch();
            }}
          >
            Accept
          </Button>
        ) : null}

      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();

          navigate('edit');
        }}
      >
        Edit
      </Button>

      {order?.loading ? (
        <Spin size="small" />
      ) : null}

      {/* {order?.data?.status ? ( */}
      <Dropdown
        menu={{
          items: [{
            key: 'Send the order template',
            label: 'Send the order template',
            onClick: ({ domEvent }) => {
              domEvent.preventDefault();
              orderSendTemplateEmail.fetch();
            },
          }, ...(items || [])],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          loading={
            orderCopy.loading
            || orderDelete.loading
            || orderProformaInvoicesGet.loading
            || orderRestoreUpdate.loading
            || orderMarkAsDelivered.loading
            || orderSendTrackingLink.loading
            || ordersTransportDocumentsGet.loading
            || orderSendTemplateEmail.loading
          }
          icon={<EllipsisOutlined />}
          style={{ transform: 'translate(0px, 1.5px)' }}
        />
      </Dropdown>
      {/* ) : null} */}

      {contextHolder}
    </Actions>
  );
}

function DownloadAllButton({ id }: { id: string }) {
  const ordersDownloadAllGet = useOrdersDownloadAllGet(id);

  useMessageError([ordersDownloadAllGet]);

  useEffect(() => {
    if (!ordersDownloadAllGet.error && !ordersDownloadAllGet.loading && ordersDownloadAllGet.data) {
      try {
        // Downloads files when data appears
        window.location.assign(window.URL.createObjectURL(ordersDownloadAllGet.data));
      } catch (error) {
        console.warn(error);
      }
    }
  }, [ordersDownloadAllGet.data]);

  return (
    <Button
      loading={ordersDownloadAllGet.loading}
      onClick={(e) => {
        e.preventDefault();
        ordersDownloadAllGet.fetch();
      }}
    >
      Download all
    </Button>
  );
}

export default function OrderView(): React.ReactNode | null {
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const orderUpdate = useOrderUpdate(id || '');
  const company = useRef<string>('');
  const { order, clientOrderData } = useOrderContext();
  const { ordersTransportDocumentsGet, orderProformaInvoicesGet } = useOrderContextForm();

  useMessageError([orderUpdate]);

  const isGoodsHasDangerousMark = useMemo(() => (
    (clientOrderData?.goods?.goods || []).some((item) => item?.dangerousGoods)
  ), [clientOrderData]);

  useEffect(() => {
    if (!orderUpdate.error && !orderUpdate.loading && orderUpdate.data) {
      order.fetch();
    }
  }, [orderUpdate.data]);

  const itemRender = useCallback((route: ItemType) => {
    if (route.key === 'last' && order?.loading) {
      return <Spin size="small" />;
    }

    return route.title;
  }, [order]);

  const tabEditDisabled = useMemo(() => (
    clientOrderData ? isOrderTabsDisabled(clientOrderData.status) : false
  ), [clientOrderData]);

  return (
    <>
      <Breadcrumb
        className="transparent"
        itemRender={itemRender}
        items={[
          {
            title: <NavLink to="/orders">Orders</NavLink>,
          },
          {
            key: 'last',
            title: `Order${clientOrderData ? ` №${clientOrderData?.delivery?.orderNumber}` : ''}`,
          },
        ]}
      />

      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.company}>
            <div className={styles.header}>
              <h2>Company</h2>
              <div>
                <Button
                  onClick={() => {
                    open({
                      icon: <div />,
                      closable: true,
                      title: 'Change company',
                      content: (
                        <SelectCompany
                          name="bla"
                          style={{
                            marginTop: '12px',
                            marginBottom: 0,
                          }}
                          selectProps={{
                            onChange: (value) => {
                              company.current = value;
                            },
                          }}
                        />
                      ),
                      onCancel: () => {
                        company.current = '';
                      },
                      cancelText: 'Cancel',
                      okText: 'Apply',
                      onOk: () => (company.current ? orderUpdate.fetch({
                        company: company.current,
                      }) : undefined),
                    });
                  }}
                >
                  Change
                </Button>
                {contextHolder}
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.row}>
                {clientOrderData?.company.companyName ? (
                  <a href={`/companies/${clientOrderData?.company.id}`}>{clientOrderData?.company.companyName}</a>
                ) : null}
                {clientOrderData?.company.contactName ? (
                  <div>{clientOrderData?.company.contactName}</div>
                ) : null}
                {clientOrderData?.company.phone ? (
                  <a href={`tel:${clientOrderData?.company.phone}`}>{clientOrderData?.company.phone}</a>
                ) : null}
                {clientOrderData?.company.email ? (
                  <a href={`mailto:${clientOrderData?.company.email}`}>{clientOrderData?.company.email}</a>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.order}>
            <div className={styles.content}>
              <b>Order No.</b>
              <div>
                {clientOrderData?.delivery?.orderNumber}
              </div>
              <div className={styles.row}>
                <b>Order</b>
                {dayjs(order?.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
              </div>
              <div className={styles.row}>
                {clientOrderData?.status ? (
                  <Tag color={getOrderStatusColor(clientOrderData?.status)} style={{ width: 'auto' }}>
                    {clientOrderData?.status}
                  </Tag>
                ) : null}
                {clientOrderData?.general.urgent ? (
                  <Tag icon={<ClockCircleOutlined />} style={{ width: 'auto' }}>
                    Urgent
                  </Tag>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.general}>
            <div className={styles.header}>
              <h2>General</h2>
              <div>
                <Button
                  onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=General data`)}
                  disabled={tabEditDisabled}
                >
                  Edit
                </Button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Mode of transport</b>
                    {clientOrderData?.general.modeOfTransport}
                  </div>
                  <div>
                    <b>Consignment date</b>
                    {order?.data?.consignmentDate
                      ? dayjs(order?.data?.consignmentDate).utc().format('DD/MM/YYYY') : '-'}
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Pickup date & Time</b>
                    {clientOrderData?.general.pickupTime && clientOrderData?.general.pickupTime.length === 2
                      ? `${clientOrderData?.general.pickupTime[0]?.utc().format('DD/MM/YYYY') || ''} `
                      + `${clientOrderData?.general.pickupTime[0]?.utc().format('HH:mm') || ''}-`
                      + `${clientOrderData?.general.pickupTime[1]?.utc().format('HH:mm') || ''}`
                      : '-'}
                  </div>
                  <div>
                    <b>Delivery terms</b>
                    {clientOrderData?.general.deliveryTerms || '-'}
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Transport Insurance</b>
                    {clientOrderData?.general.insuranceValue && clientOrderData?.general.insuranceCurrency ? (
                      <>
                        {' '}
                        {clientOrderData?.general.insuranceValue}
                        {' '}
                        {clientOrderData?.general.insuranceCurrency}
                      </>
                    ) : '-'}
                  </div>
                  <div>
                    <b>Waybill No.</b>
                    {clientOrderData?.general.waybillNo || '-'}
                  </div>
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Preferred delivery service</b>
                    {' '}
                    {clientOrderData?.general?.preferredDeliveryService ? (
                      DeliveryServiceDisplayName[clientOrderData?.general?.preferredDeliveryService]) : '-'}
                  </div>
                  <div>
                    <b>Billing account number</b>
                    {' '}
                    {clientOrderData?.general.billingNumber || '-'}
                  </div>

                  {clientOrderData?.general.preferredDeliveryService === 'fedex' ? (
                    <>
                      <div>
                        <b>Billing country code</b>
                        {clientOrderData?.general.billingNumberCountryCode || '-'}
                      </div>
                      <div>
                        <b>Billing code</b>
                        {' '}
                        {(clientOrderData?.general?.billingCode || '-').replaceAll('_', ' ')}
                      </div>
                      <div>
                        <b>Billing type</b>
                        {(clientOrderData?.general?.billingType || '-').replaceAll('_', ' ')}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className={styles.row}>
                <b>Important notes for Sephyre</b>
                <div>
                  {clientOrderData?.general.notes || '-'}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.delivery}>
            <Tracking
              title="Delivery"
              actions={(
                <Button onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=Delivery`)}>
                  Edit
                </Button>
              )}
              trackingNumber={clientOrderData?.delivery?.trackingNumber}
              deliveryService={clientOrderData?.delivery?.deliveryService}
              trackingLink={clientOrderData?.delivery?.trackingLink}
            />
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.shipper}>
            <div className={styles.header}>
              <h2>Shipper</h2>
              <div>
                <Button
                  onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=Shipper`)}
                  disabled={tabEditDisabled}
                >
                  Edit
                </Button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Shipper details</b>
                    <div>{clientOrderData?.shipper.company}</div>
                    <div>{clientOrderData?.shipper.contactName}</div>
                    <a href={`tel:${clientOrderData?.shipper.phone}`}>{clientOrderData?.shipper.phone}</a>
                    <a href={`mailto:${clientOrderData?.shipper.email}}`}>{clientOrderData?.shipper.email}</a>
                    <div>
                      {clientOrderData?.shipper.address}
                      {' '}
                      {clientOrderData?.shipper.address2}
                    </div>
                    <div>{clientOrderData?.shipper.postalCode}</div>
                    <div>{clientOrderData?.shipper.country}</div>
                    <div>{clientOrderData?.shipper.state}</div>
                    <div>{clientOrderData?.shipper.city}</div>
                    <div>
                      TIN no.
                      {' '}
                      {clientOrderData?.shipper.tin}
                    </div>
                    <div>
                      Reg. no.
                      {' '}
                      {clientOrderData?.shipper.registerNumber}
                    </div>
                    <div>
                      Reference
                      {' '}
                      {clientOrderData?.shipper.reference}
                    </div>
                  </div>
                  <div>
                    <b>Pickup details</b>
                    {clientOrderData?.shipper.isAddressDifferent ? (
                      <>
                        <div>{clientOrderData?.shipper.details.contactName}</div>
                        <a href={`tel:${clientOrderData?.shipper.details.phone}`}>
                          {clientOrderData?.shipper.details.phone}
                        </a>
                        <a href={`mailto:${clientOrderData?.shipper.details.email}`}>
                          {clientOrderData?.shipper.details.email}
                        </a>
                        <div>
                          {clientOrderData?.shipper.details.address}
                          {' '}
                          {clientOrderData?.shipper.details.address2}
                        </div>
                        <div>{clientOrderData?.shipper.details.postalCode}</div>
                        <div>{clientOrderData?.shipper.details.country}</div>
                        <div>{clientOrderData?.shipper.details.state}</div>
                        <div>{clientOrderData?.shipper.details.city}</div>
                      </>
                    ) : (
                      <span style={{ fontSize: '16px' }}>The same as Shipper details</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.importer}>
            <div className={styles.header}>
              <h2>Consignee</h2>
              <div>
                <Button
                  onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=Consignee`)}
                  disabled={tabEditDisabled}
                >
                  Edit
                </Button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.col2}>
                  <div>
                    <b>Importer details</b>
                    <div>{clientOrderData?.importer.company}</div>
                    <div>{clientOrderData?.importer.contactName}</div>
                    <a href={`tel:${clientOrderData?.importer.phone}`}>{clientOrderData?.importer.phone}</a>
                    <a href={`mailto:${clientOrderData?.importer.email}}`}>{clientOrderData?.importer.email}</a>
                    <div>
                      {clientOrderData?.importer.address}
                      {' '}
                      {clientOrderData?.importer.address2}
                    </div>
                    <div>{clientOrderData?.importer.postalCode}</div>
                    <div>{clientOrderData?.importer.country}</div>
                    <div>{clientOrderData?.importer.state}</div>
                    <div>{clientOrderData?.importer.city}</div>
                    <div>
                      TIN no.
                      {' '}
                      {clientOrderData?.importer.tin}
                    </div>
                    <div>
                      Reg. no.
                      {' '}
                      {clientOrderData?.importer.registerNumber}
                    </div>
                    <div>
                      Reference
                      {' '}
                      {clientOrderData?.importer.reference}
                    </div>
                  </div>
                  <div>
                    <b>Delivery details</b>
                    {clientOrderData?.importer.isAddressDifferent ? (
                      <>
                        <div>{clientOrderData?.importer.details.contactName}</div>
                        <a href={`tel:${clientOrderData?.importer.details.phone}`}>
                          {clientOrderData?.importer.details.phone}
                        </a>
                        <a href={`mailto:${clientOrderData?.importer.details.email}`}>
                          {clientOrderData?.importer.details.email}
                        </a>
                        <div>
                          {clientOrderData?.importer.details.address}
                          {' '}
                          {clientOrderData?.importer.details.address2}
                        </div>
                        <div>{clientOrderData?.importer.details.postalCode}</div>
                        <div>{clientOrderData?.importer.details.country}</div>
                        <div>{clientOrderData?.importer.details.state}</div>
                        <div>{clientOrderData?.importer.details.city}</div>
                      </>
                    ) : (
                      <span style={{ fontSize: '16px' }}>The same as Importer details</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.products}>
          <div className={styles.header}>
            <h2>Products</h2>
            <div>
              <Button
                onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=Products`)}
                disabled={tabEditDisabled}
              >
                Add
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <Product
                formName="products"
                key={JSON.stringify(clientOrderData?.goods)}
                list={clientOrderData?.goods.goods}
                update={() => {
                  order.fetch();
                }}
              />
            </div>
          </div>
        </div>

        <div className={clsx(styles.documents, 'documents')}>
          <div className={styles.header}>
            <h2>Documents</h2>
            <div>
              {typeof clientOrderData?.id === 'string' ? (
                <DownloadAllButton id={clientOrderData?.id} />
              ) : null}
              <Button onClick={() => navigate(`/orders/${clientOrderData?.id}/edit?tab=Documents`)}>
                Edit
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div>
              <div>
                <div className={styles.title}>
                  Transport Documents
                  {' ('}
                  <a href="https://www.dgassistant.com/en/index.aspx">DG Assistant</a>
                  )
                </div>
                <div className={styles.btn}>
                  <Button
                    icon={<ReloadOutlined />}
                    className={styles.generate}
                    loading={ordersTransportDocumentsGet.loading}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isGoodsHasDangerousMark) {
                        message.error(
                          'You can\'t generate dangerous goods documents without any dangerous goods selected',
                        );
                      } else {
                        ordersTransportDocumentsGet.fetch();
                      }
                    }}
                  >
                    Generate
                  </Button>
                </div>
                <div className={styles.list}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {ordersTransportDocumentsGet.data && ordersTransportDocumentsGet.data.length > 0 ? (
                    ordersTransportDocumentsGet.data.map(({ location, originalName }) => (
                      <div key={location}>
                        <a href={location} target="_blank" rel="noreferrer" download={originalName}>
                          <PaperClipOutlined />
                          {originalName}
                        </a>
                      </div>
                    )))
                    : (
                      clientOrderData?.documents?.transportDocuments?.fileList?.length
                        ? clientOrderData?.documents.transportDocuments.fileList.map(({ url, name }) => (
                          <div key={url}>
                            <a href={url} target="_blank" rel="noreferrer" download={name}>
                              <PaperClipOutlined />
                              {name}
                            </a>
                          </div>
                        )) : null
                    )}
                </div>
              </div>
              {/*  <div> */}
              {/*    <div className={styles.title}> */}
              {/*      Product documents */}
              {/*    </div> */}
              {/*    <div className={styles.list}> */}
              {/*      {clientOrderData?.goods?.goods?.length */}
              {/*        ? clientOrderData?.goods?.goods.map((good) => ( */}
              {/*          good.msdsDocument?.fileList?.map(({ url, name }) => ( */}
              {/*            <div key={url}> */}
              {/*              <a href={url} target="_blank" rel="noreferrer" download={name}> */}
              {/*                <PaperClipOutlined /> */}
              {/*                {name} */}
              {/*              </a> */}
              {/*            </div> */}
              {/*          )) */}
              {/*        )) : null} */}
              {/*      {clientOrderData?.goods?.goods?.length */}
              {/*        ? clientOrderData?.goods?.goods.map((good) => ( */}
              {/*          good.exportLicenseDocument?.fileList?.map(({ url, name }) => ( */}
              {/*            <div key={url}> */}
              {/*              <a href={url} target="_blank" rel="noreferrer" download={name}> */}
              {/*                <PaperClipOutlined /> */}
              {/*                {name} */}
              {/*              </a> */}
              {/*            </div> */}
              {/*          )) */}
              {/*        )) : null} */}
              {/*    </div> */}
              {/*  </div> */}
            </div>

            <div>
              <div>
                <div className={styles.title}>
                  Proforma Invoice
                </div>
                <div className={styles.btn}>
                  <Button
                    icon={<ReloadOutlined />}
                    className={styles.generate}
                    loading={orderProformaInvoicesGet.loading}
                    onClick={(e) => {
                      e.preventDefault();
                      orderProformaInvoicesGet.fetch();
                    }}
                  >
                    Generate
                  </Button>
                </div>
                <div className={styles.list}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {clientOrderData?.documents?.proformaInvoices?.fileList?.length
                    ? clientOrderData.documents.proformaInvoices.fileList.map(({ url, name }) => (
                      <div key={url}>
                        <a
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                          download={name}
                        >
                          <PaperClipOutlined />
                          {name}
                        </a>
                      </div>
                    )) : (orderProformaInvoicesGet.data ? (
                      <div>
                        <a
                          href={orderProformaInvoicesGet.data.location}
                          target="_blank"
                          rel="noreferrer"
                          download={orderProformaInvoicesGet.data.originalName}
                        >
                          <PaperClipOutlined />
                          {orderProformaInvoicesGet.data.originalName}
                        </a>
                      </div>
                    ) : null)}
                </div>
              </div>
              <div>
                <div className={styles.title}>
                  Additional documents
                </div>
                <div className={styles.list}>
                  {clientOrderData?.documents?.additionalDocuments?.fileList?.length
                    ? clientOrderData.documents.additionalDocuments.fileList.map(({ url, name }) => (
                      <div key={url}>
                        <a href={url} target="_blank" rel="noreferrer" download={name}>
                          <PaperClipOutlined />
                          {name}
                        </a>
                      </div>
                    )) : null}
                </div>
              </div>

              <div>
                <span>Emergency phone number: </span>
                {clientOrderData?.documents?.emergencyPhoneNumber}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
